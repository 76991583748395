import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg';
import { useEffect, useState } from "react";
import Axios from "axios";
import { ThreeDots } from  'react-loader-spinner';

const Jobs = () =>{

    useEffect(() => {
        window.scrollTo(0, 0);
        Axios.get("https://cabinet-secls.herokuapp.com/api/read").then( (res) => {
            setOfferList(res.data);
            setShowSpinner(false);
        })        
      }, []);

    const [offerList, setOfferList] = useState([]);
    const [showSpinner, setShowSpinner] = useState(true);

    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Nous rejoindre<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Carrière</a></li>
                    </ul>
                </nav>
            </div>
        </div>

        <div className="jobs-container" align="center">
            <div className="container-offers" align="center">
                <p className='has-text-primary'>Carrière</p>
                <h1 className="title is-1">Nos offres d'emploi</h1>
                <div className="container-renderOfferClient">
                    {showSpinner ? <><ThreeDots color="red" height={100} width={100} /> <p>Chargement en cours</p></> : 
                        <>
                        {offerList.length  > 0
                            ? 
                            offerList.map((val) => {
                                return (
                                    <div className="renderOfferClient has-text-centered">
                                        <h1 className="title is-3">{val.title}</h1>
                                        <h1 className="title is-6 has-text-primary">{val.type} - Temps plein {val.duration ? `(${val.duration})` : null}</h1>
                                        <h1 className="title is-5 has-text-left">Description du Poste</h1>
                                        <h1 className="title is-6 has-text-left"><br /><br /> Pourquoi nous rejoindre</h1>
                                        <p className="has-text-justified">{val.description}</p>
                                        <h1 className="title is-6 has-text-left"><br /><br /> Missions</h1>
                                        <p className="has-text-justified">{val.missions}</p>
                                        <p className="has-text-justified">{val.description}</p>
                                        <h1 className="title is-6 has-text-left"><br /><br /> Votre Profil</h1>
                                        <p className="has-text-justified">{val.formation}</p>
                                        <p className="has-text-justified">{val.experience}</p>
                                        <h1 className="title is-6 has-text-left"><br /><br /> Vos Avantages</h1>
                                        <p className="has-text-justified">{val.avantages}</p>
                                        <h1 className="title is-6 has-text-left"><br /><br /> Horaires</h1>
                                        <p className="has-text-justified">{val.hours}</p>
                                        <h1 className="title is-5 has-text-left"><br /><br /> Vous êtes intéressé ?</h1>
                                        <p className="has-text-justified">Candidature à <a href="mailto:contact@secls.fr">contact@secls.fr</a></p>
                                    </div>
                                    )
                            }) 

                        :   

                        <>
                        <p>Il n'y a actuellement aucune offre à pourvoir.
                        <br/>Envoyez vos candidatures spontannées à <a href="mailto:contact@secls.fr">contact@secls.fr</a> .
                        </p>
                        </>
                    }
                    </>
                }
                </div>
             </div>
        </div>
        <Footer/>
        </>
    );
}

export default Jobs;