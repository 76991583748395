import Footer from "./Footer";
import Header from "./Header";
import { useEffect, useState } from "react";

function Equipe() {

    useEffect(() => {
        window.scrollTo(0, 0);
    
      }, []);

      
    return ( 
        <>
         <Header/>
            <div className='container-equipe2'>
            <p className='has-text-primary has-text-centered'>Qui Sommes-nous ?</p>
            <h1 className='title is-2 has-text-centered'>L'Équipe SECLS</h1>
            
            <div className="container-details">
                <h1 className="title is-4">Luc LECOMTE</h1>
                <p className="">Expert-Comptable / Commissaire Aux Comptes</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:luc.lecomte@secls.fr"> luc.lecomte@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.39</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Clément MILLET</h1>
                <p className="">Expert-Comptable Mémoraliste</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:clement.millet@secls.fr"> clement.millet@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.01</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Aurélie HECQUET</h1>
                <p className="">Secrétariat et Juridique</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:contact@secls.fr"> contact@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.39</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Alexia FAMIN</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:alexia.famin@secls.fr"> alexia.famin@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.67</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Corinne SAUVIAT</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:corinne.sauviat@secls.fr"> corinne.sauviat@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.16</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Etienne SAUVIAT</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:etienne.sauviat@secls.fr"> etienne.sauviat@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.74</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Nathalie SAMAKE</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:nathalie.samake@secls.fr"> nathalie.samake@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.11</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Olivier CHENEAU</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:olivier.cheneau@secls.fr"> olivier.cheneau@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.45</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Isabelle JANY</h1>
                <p className="">Service Social</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:isabelle.jany@secls.fr"> isabelle.jany@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.78.64.95.10</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Sylvie LECOMTE</h1>
                <p className="">Service Social</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:sylvie.lecomte@secls.fr"> sylvie.lecomte@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.20</p>
            </div>


        </div>
        <Footer/>
        </>
     );
}

export default Equipe;