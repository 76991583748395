import Footer from "../Footer";
import Header from "../Header";
import bg from '../../img/aProposBg.jpg'
import trait from '../../img/trait-rouge.png'
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

const People = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white' id="big2">PeopleDoc<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Services&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;PeopleDoc</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="consult-container">
            <div className="service-only-container">
                <h1 className="title is-3 has-text-centered ligne">
                Une offre évolutive, répondant aux enjeux de digitalisation des fonctions RH et facile d’intégration
                </h1>
                <img src={trait} alt="" />
                <p>
                <br />
                Notre service <span className="has-text-grey">PeopleDoc</span> est issue du parteneriat entre Cegid et myPeopleDoc. La digitalisation des processus RH est un enjeu majeur à relever par les entreprises dans le cadre de leur transformation numérique globale, elle intervient notamment autour de plusieurs axes forts : optimisation de l’expérience salarié, automatisation des flux et gestion des talents.<br />
                <br />
                Cette solution intègre parfaitement le besoin de dématérialisation des bulletins de paie et des processus des entreprises en prenant en compte les attentes des DRH et services RH (sécurisation des données, optimisation des coûts) ainsi que celles des salariés (gestion et visibilité de leurs documents RH personnels), sur un marché constamment impacté par de nouvelles réglementations. <br /><br />
                </p>
                
                <h1 className="title is-3 has-text-centered ligne">
                Qu’est-ce-que PeopleDoc ?
                </h1>
                <img src={trait} alt="" />
                
                <p>
                <br />
                A destination de tous les segments d’entreprises, PME, ETI et GE, cette offre 100% cloud garantit aux entreprises une mise en conformité avec les dernières réglementations légales (Loi El-Khomri, CPA, lutte contre la fraude…). Cette solution vous permettra tout particulièrement d’être en conformité avec la RGPD, mise en vigueur depuis mai 2018.
                <br /><br /><br />
                </p>
                
                <div align="center">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/EOpnmOyxSnQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                
                <h1 className="title is-3"><br/>Un nouveau service innovant 100% Cloud de Dématérialisation des processus et des documents RH. </h1>

                <p>En mode SaaS, déployable en quelques minutes et ne nécessitant peu ou pas de formation, cette nouvelle offre permet aux entreprises de s’affranchir de toutes les contraintes d’intégration. Afin de faciliter l’assimilation de ces processus de dématérialisation RH, cette solution a été pensée et conçue de manière à s’intégrer totalement aux solutions de paie de notre Cabinet.</p>

                <p><strong><br /> Peopledoc, solution SaaS de digitalisation RH aide les entreprises à simplifier et fluidifier les processus administratifs entre RH et salariés.</strong></p>
                
                <h1 className="title is-3 has-text-centered ligne">
                <br/>Se former à PeopleDoc
                </h1>
                <img src={trait} alt="" />
               
                <p><br />Vous pouvez sur demande, auprès de votre interlocuteur privilégié au sein du Cabinet, vous former à la maîtrise de ce logiciel de comptabilité afin de gérer la tenue comptable de votre entreprise.<br /><br /></p>
                
                <button className="button is-primary is-large button-special"><NavLink exact to='/formulaire' className="has-text-white">Ce service m'intéresse</NavLink></button>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default People;