import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg'
import { useEffect, useState } from "react";

const Legal = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
        <>
        <Header/>
            <div className="container-legal">
                <div className="legal">
                    <h1 className="title is-1 has-text-centered">Politique de Confidentialité</h1>

                    <h1 className="title is-3"><br/><br/>1. RÉALISATION ÉDITORIALE</h1>
                    <p>
                    Le site accessible à l’adresse suivante : cabinet-secls.fr (ci-après le « Site ») est la propriété de :
                    <br/><br/>
                    SOC EXPERTISE COMPTABLE LECOMTE SAUVIAT (SECLS) <br/>
                    Numéro de Siren : 709804991<br/>
                    12 AV DE SAINT GERMAIN 78160 MARLY-LE-ROI <br/>
                    Représenté par Monsieur Luc Lecomte, en sa qualité de Président.<br/><br/>

                    La direction de la publication est assurée par Monsieur Luc Lecomte.<br/><br/>

                    L’équipe éditoriale est composée de permanents du SECLS.
                    </p>
                    <h1 className="title is-3"><br/><br/>2. IDENTITÉ ET COORDONNÉES DE L’HÉBERGEUR</h1>
                    <p>
                    Le présent Site est hébergé par :
                    <br/><br/>
                    OVH<br/>
                    SAS au capital de 10 174 560 €<br/>
                    RCS Lille Métropole 424 761 419 00045<br/>
                    Code APE 2620Z<br/>
                    N° TVA : FR 22 424 761 419<br/>
                    Siège social : 2 rue Kellermann - 59100 Roubaix - France<br/>
                    <br/>
                    Président : Michel Paulin<br/>

                    OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.
                
    
                    </p>

                    <h1 className="title is-3"><br/><br/>3. DROITS DE REPRODUCTION</h1>
                    <p>
                    Tous les contenus présents sur le Site, à l’exception de ceux ajoutés par les Utilisateurs inscrits dans leur espace réservé, sont et demeurent la propriété du Cabinet SECLS et sont donc couverts par le droit d'auteur.
                    <br/><br/>
                    Les marques du Conseil national de l’Ordre des experts-comptables ainsi que les logos figurant sur le Site ont été déposés auprès de l’INPI. Toute reproduction totale ou partielle de ces marques ou de ces logos, effectuée à partir des éléments du Site sans l’autorisation expresse de SECLS , sur quelque support que ce soit, est formellement interdite, au sens des articles L.713-2 et suivants du Code de la propriété intellectuelle.
                    <br/><br/>
                    Chaque cabinet d’expertise comptable demeure propriétaire du logo qu’il choisit d’ajouter dans son espace réservé sur le Site et garantit que ce logo a été déposé auprès de l’INPI.
                    </p>
                    <h1 className="title is-3"><br/><br/>4. RESPONSABILITÉ</h1>
                    <p>
                    Le Cabinet SECLS est engagé dans la protection de la vie privée de chaque individu.
                    <br/><br/>
               
                    </p>
                    <h1 className="title is-3"><br/><br/>5. PROTECTION DES DONNÉES PERSONNELLES</h1>
                    <p>
                    Le Cabinet SECLS est engagé dans une démarche continue de protection des données personnelles de ses internautes, en conformité avec la Loi Informatique et Libertés du 6 janvier 1978 en vigueur (ci-après « LIL ») et le règlement européen n° 2016/679, dit règlement général sur la protection des données (ci-après « le RGPD »). Vous pouvez consulter la politique de confidentialité et de gestion des cookies du CNOEC en cliquant sur les liens dans le pied de page.
                    <br/><br/>
                    « Utilisateur » désigne l’expert-comptable, le prospect ou le client qui visite une ou plusieurs pages du Site pour consulter gratuitement les informations disponibles.
                    
                    <br/> <br/><strong>IDENTITE DU RESPONSABLE DE TRAITEMENT</strong> <br/> <br/>

                    Le responsable du traitement est, selon le RGPD, la personne qui détermine les moyens et les finalités du traitement.  

                    Le responsable de traitement des données personnelles collectées dans le cadre de la navigation de l’Utilisateur sur le Site est :
                    Cabinet SECLS
                    
                    
                    <br/> <br/><strong>TYPE DE DONNEES PERSONNELLES TRAITEES</strong> <br/> <br/>
                    
                    En vertu de la LIL et du RGPD, toute information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres, constitue une donnée à caractère personnel, aussi appelée donnée personnelle.
                    <br/><br/>
                    Le Cabinet SECLS veille à ne collecter et ne traiter que des données strictement nécessaires au regard de la finalité pour laquelle elles sont traitées, suivant le principe de minimisation des données tel que défini à l’article 5-1 c) du RGPD.
                    <br/><br/>
                     Des données personnelles d’identification (nom, prénom, identifiant de connexion, adresse IP, photographie) et de contact (adresse e-mail, numéro de téléphone professionnel, adresse professionnelle) ainsi que les données professionnelles relatives aux langues parlées et aux compétences spécialisées des Utilisateurs seront traitées par le Cabinet SECLS dans le cadre de leur navigation, de leur inscription sur le Site et/ou de la mise en relation entre les experts-comptables et leurs prospects ou clients.
                    
                    
                     <br/> <br/><strong>BASE LEGALE DU TRAITEMENT</strong> <br/> <br/>
                     Toutes les données personnelles concernant les Utilisateurs sont collectées directement auprès de ces derniers. Le traitement de ces données personnelles repose sur l’intérêt légitime de faciliter la mise en relation entre les experts-comptables et les prospects.
                    
                     <br/> <br/><strong>DUREE DE CONSERVATION DES DONNEES PERSONNELLES</strong> <br/> <br/>
                     Les données personnelles sont conservées uniquement le temps nécessaire à l’accomplissement de la finalité poursuivie par le traitement des données personnelles.
                    <br/><br/>
                     Les données personnelles collectées par l’intermédiaire du formulaire de contact entre le prospect et le cabinet d’expertise comptable seront conservées pendant un (1) an à compter de l’envoi du formulaire.
                     <br/><br/>
                     Les données collectées par l’intermédiaire de cookies seront, pour leur part, conservées pendant 13 mois.

                     <br/> <br/><strong>LES DROITS DES UTILISATEURS</strong> <br/> <br/>
                     Les Utilisateurs disposent d’un droit d'accès, de rectification, de limitation, d'opposition pour motifs légitimes, d’un droit à l’oubli, , du droit de définir des directives relatives au sort de vos données personnelles après la mort et d’un droit à la portabilité sous réserve des dispositions légales et réglementaires applicables. Ils sont également libres de retirer le consentement au traitement de leurs données à tout moment.
                     <br/><br/>
                     Ces droits s’exercent, en justifiant de son identité via l'adresse mail contact@secls.fr . 
                     <br/><br/>
                     En cas de réclamation à laquelle le Cabinet SECLS n’aurait pas donné de réponse satisfaisante, l’Utilisateur a la possibilité de déposer une réclamation directement auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL) : 3 Place de Fontenoy – TSA 80715 – 75334 Paris 07.
                     
                     <br/> <br/><strong>LES COOKIES</strong> <br/> <br/>
                     Selon la définition de la Commission Nationale de l’Informatique et des Libertés (CNIL), « un “cookie” est une suite d’informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y reconnecterez. Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d’un site marchand, le contenu courant de votre panier d’achat, un identifiant permettant de tracer votre navigation pour des finalités statistiques ou publicitaires, etc. ».
                     <br/><br/>
                     L'utilisation des cookies est soumise au consentement de l’Utilisateur dès lors qu'ils ne sont pas strictement nécessaires au fonctionnement du site concerné.
                    <br/><br/>
                     L’Utilisateur peut également configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non accepter les cookies de manière à ce que des cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur.
                     <br/><br/>
                     Le présent site web n'utilise aucun cookie de navigation.
                    </p>
                    <h1 className="title is-3"><br/><br/>6. FONCTIONNEMENT</h1>
                    <p>Pour toute question technique relative Site, l’Utilisateur peut adresser un message au webmaster à cette adresse : remydionisio@outlook.fr .</p>
                    <br/><br/><br/><br/><br/><br/>
                
                </div>
            </div>
        <Footer/>
        </>
    );
}

export default Legal;