import bg from '../img/aProposBg.jpg';
import compt from '../img/iconComptable.png';
import social from '../img/iconSocial.png';
import juridique from '../img/iconJuridique.png';
import conseil from '../img/iconConseil.png';
import com from '../img/iconCom.png';

import Footer from './Footer';
import Header from './Header';
import { useEffect, useState } from "react";

function Missions() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);


    return ( 
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Nos Missions<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Nos Missions</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="container-missions">
                <p className='has-text-primary'>Cabinet SECLS</p>
                <h1 className="title is-1">Nos Missions</h1>
                <div className="container-content-missions">
                    <p className='has-text-justified'>L'expert-comptable, dispose de compétences étendues pour vous suivre et vous conseiller durant toute la vie de votre entreprise.

                    Notre cabinet met à votre disposition un savoir-faire reconnu en matière fiscale, sociale, juridique ou encore de conseils.
                    En relation constante avec les organes décisionnels de votre entreprise, il vous assure une prestation fiable et rapide ainsi qu'un conseil permanent dans les domaines cruciaux de la vie de votre entreprise. Nous nous impliquons directement dans votre stratégie de réussite et nous nous engageons à vos côtés afin de contribuer à faire de votre structure une entreprise dynamique et adaptée aux besoins de votre clientèle.
                    </p>

                    <div className="columns missions">
                        <div className="column bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={compt} alt="" className='iconMissions'/>
                                <h1 className="title">Missions Comptables</h1>
                                </div>

                                <h1 className="title is-5"><br /> Participation à l'établissement des comptes</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Mise en place du Plan comptable adapté à l'entreprise</span>  <br />
                                    • <span className="petit has-text-left">Surveillance, tenue de comptabilités</span>  <br />
                                    • <span className="petit has-text-justified">Contrôle des services comptables </span>  <br />
                                    • <span className="petit has-text-justified">Mise en place et suivi de la comptabilité analytique d'exploitation</span>  <br />
                                    • <span className="petit has-text-justified">Assistance comptable de toute nature</span>  <br />
                                    • <span className="petit has-text-justified">Établissement des situations périodiques, des bilans et comptes de résultat</span>  <br />
                                    • <span className="petit has-text-justified">Délivrance du visa aux adhérents des centres de gestion agréés</span>  <br />
                                    • <span className="petit has-text-justified">Établissement de situations prévisionnelles</span>  <br />
                                    • <span className="petit has-text-justified">Établissement des comptes de groupes, consolidation des bilans et des comptes</span>  <br />
                                    • <span className="petit has-text-justified">Établissement du tableau de financement</span>  <br />
                                </div>
                                <h1 className="title is-5"><br />Révision & Certification</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Révision contractuelle des comptes</span>  <br />
                                    • <span className="petit has-text-left">Révision des procédures de contrôle interne </span>  <br />
                                    • <span className="petit has-text-justified">Révision légale des comptes (commissariat aux comptes) </span>  <br />
                                    • <span className="petit has-text-left">Révision avant prise de participation ;</span>  <br />
                                    • <span className="petit has-text-left">Commissariat aux apports </span>  <br />
                                </div>
                            </div>
                            
                        </div>
                        <div className="column icon2 bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={juridique} alt="" className='iconMissions2'/>
                                <h1 className="title">Missions Juridiques & Fiscales</h1>
                                </div>

                                <h1 className="title is-5"><br /> Droit des sociétés</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Choix de la forme sociale </span>  <br />
                                    • <span className="petit has-text-left">Réalisation de toutes opérations (constitution, augmentation de capital, cession de parts, fusion, transformation, scission, dissolution, liquidation) </span>  <br />
                                    • <span className="petit has-text-justified">Secrétariat de sociétés : comptes-rendus de conseils d'administration et d'assemblées générales et formalités afférentes</span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Droit de l'entreprise individuelle</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Droit patrimonial </span>  <br />
                                    • <span className="petit has-text-left">Prévoyance sociale du chef d'entreprise </span>  <br />
                                    • <span className="petit has-text-justified">Transmission</span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Droit fiscal</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Études et consultations sur les problèmes d'ordre fiscal </span>  <br />
                                    • <span className="petit has-text-left">Participation à l'établissement des déclarations fiscales : impôts directs, TVA et taxes assimilées, fiscalité immobilière, droits d'enregistrement et droits de mutation, douanes, autres impôts et taxes</span>  <br />
                                    • <span className="petit has-text-justified">Assistance du contribuable à l'occasion de vérifications</span>  <br />
                                </div>
                            </div>
                            
                        </div>

                    </div>

                    <div className="columns missions">
                        <div className="column bgcolumn">
                            <div>
                            <div align="center">
                                    <img src={conseil} alt="" className='iconMissions2'/>
                                    <h1 className="title">Missions de Conseil</h1>
                                </div>

                                <h1 className="title is-5"><br /> Conseil en gestion générale</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Gestion prévisionnelle</span>  <br />
                                    • <span className="petit has-text-left">Analyse de coûts, de marges, de rentabilité</span>  <br />
                                    • <span className="petit has-text-justified">Projets d'investissement : coût et rentabilité </span>  <br />
                                    • <span className="petit has-text-justified">Tableaux de bord, contrôle budgétaire</span>  <br />
                                    • <span className="petit has-text-justified">Étude d'optimisation du profil </span>  <br />
                                    • <span className="petit has-text-justified">Ratios de gestion</span>  <br />
                                </div>
                                <h1 className="title is-5"><br /> Relations avec les organismes bancaires et financiers </h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Constitution de dossiers dans le cadre de créations et d'implantations d'entreprise</span>  <br />
                                    • <span className="petit has-text-left">Analyse de structure financière</span>  <br />
                                    • <span className="petit has-text-justified">Établissement de plans de trésorerie </span>  <br />
                                    • <span className="petit has-text-justified">Construction de plans de financement à moyen et long terme</span>  <br />
                                    • <span className="petit has-text-justified">Choix des modes de financement et établissement de dossiers de crédit </span>  <br />
                                    • <span className="petit has-text-justified">Tableau d'emplois et de ressources (historique et prévisionnel)</span>  <br />
                                </div>

                            </div>
                            
                        </div>
                        <div className="column icon2 bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={social} alt="" className='iconMissions'/>
                                <h1 className="title">Missions Sociales</h1>
                                </div>
                               
                                <h1 className="title is-5"><br />Droit du travail</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Application de la législation et de la réglementation du travail </span>  <br />
                                    • <span className="petit has-text-left">Consultations sur les problèmes spécifiques au droit du travail </span>  <br />
                                    • <span className="petit has-text-justified">Étude et rédaction de contrats de travail  </span>  <br />
                                    • <span className="petit has-text-justified">Problèmes de licenciement </span>  <br />
                                    • <span className="petit has-text-justified">Problèmes relatifs à la représentation dans l'entreprise, délégués du personnel, comité d'entreprise, droit syndical </span>  <br />
                                    • <span className="petit has-text-justified">Étude et application des textes relatifs à la participation des travailleurs aux fruits de l'expansion</span>  <br />
                                    • <span className="petit has-text-justified">Système d'intéressement (mise en œuvre, contrôle) </span>  <br />
                                    • <span className="petit has-text-justified">Formation professionnelle continue</span>  <br />
                                </div>
                                <h1 className="title is-5"><br />Prévoyance sociale</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Consultation sur les problèmes spécifiques à la Sécurité sociale et à la prévoyance sociale (notamment les régimes de retraite)</span>  <br />
                                    • <span className="petit has-text-left">Établissement des bulletins de paie et de la comptabilité des salaires </span>  <br />
                                    • <span className="petit has-text-justified">Établissement des déclarations de cotisations sociales </span>  <br />
                                    • <span className="petit has-text-justified">Assistance à l'occasion de réclamation et de vérification des administrations sociales</span>  <br />
                                </div>

                            </div>
                            
                        </div>
                        <div className="column icon2 bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={com} alt="" className='iconMissionsCom'/>
                                <h1 className="title">Commissariat aux Comptes</h1>
                                </div>

                                <h1 className="title is-5"><br /> Audit Légal</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Certification des comptes annuels </span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Certification des comptes annuels</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Vérifications spécifiques </span>  <br />
                                    • <span className="petit has-text-left">Rapport de Gestion </span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Documents adressés aux actionnaires</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Conventions réglementées</span>  <br />
                                    • <span className="petit has-text-left">Interventions connexes</span>  <br />
                                    • <span className="petit has-text-left">Augmentation de capital</span>  <br />
                                    • <span className="petit has-text-left">Acomptes sur dividendes</span>  <br />
                                    • <span className="petit has-text-left">Révélations de faits délictueux</span>  <br />
                                    • <span className="petit has-text-left">Alerte</span>  <br />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
              
        </div>
        <Footer/>
        </>
     );
}

export default Missions;