import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg'
import svg from '../img/svg-arrow-down.png'
import { useEffect, useState } from "react";

const Faq = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    
      }, []);


    const handleClick = event => {    
        event.preventDefault();    
        event.currentTarget.classList.toggle("activeFaq");
   
        var body = event.currentTarget.nextElementSibling;
        if (body.style.display === "block") {
            body.style.display = "none";
        } else {
            body.style.display = "block";

        }
    }

    
      
    return(
        <>
        <Header/>
        <div className="container-bg ">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Foire aux Questions<br/><br/></h1>
                
                <nav className="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" className="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" className="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li className="is-active" ><a href="#" aria-current="page" className="has-text-grey">&nbsp;F.A.Q</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="faq-container">
            <div className="container-offers" align="center">
                <p className='has-text-primary'>F.A.Q</p>
                <h1 className="title is-1">Foire aux Questions</h1>
                
                <div className="titleFAQ">
                    <p className="has-text-justified has-text-grey">
                    <br/>
                    Notre FAQ regroupe les demandes et les questionnements les plus fréquemment soumis à nos conseillers : Comptabilité, Fiscalité, Audit, Juridique, Social…
                    <br/><br/>Trouvez ici les réponses à vos questions relevant de l’expertise comptable et du commissariat aux comptes. Pour tout complément d’information, n’hésitez à pas à nous contacter directement.</p>
                    <br/><br/>
                    <h1 className="title has-text-left">Fiscalité</h1>
                </div>
                <br/><br/>
                <div className="faq-one">

                <h1 className="faq-page has-text-left" onClick={handleClick}>Qu’est ce qu’un fichier des écritures comptables ?</h1>
    
                <div className="faq-body">
                    <p className="has-text-left"> <br/>En cas de contrôle fiscal, les entreprises qui tiennent leur comptabilité au moyen de systèmes informatisés ont l’obligation de transmettre les écritures comptables sous la forme d’un fichier unique appelé Fichier des écritures comptables (FEC).
                        <br/><br/>
                        En cas de défaut ou de remise d’un fichier non conforme (erreur, omission, insuffisance…), les sanctions sont importantes.
                        <br/><br/>
                        Il s’agit de permettre à l’administration fiscale de gagner en efficacité lors des contrôles fiscaux et qui souhaite s’assurer que les contribuables n’utilisent pas l’informatique pour altérer, modifier, voire refaire leur comptabilité ou contourner les règles fiscales.</p>
                        <br/><br/>
                </div>
            </div>
            <hr className="hr-line"/>
            <div className="faq-two">
               
                <h1 className="faq-page has-text-left" onClick={handleClick}>Quelles sont les entreprises concernées par le fichier d’écriture fiscale (FEC) ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> <br/>
                    Toutes les entreprises sont concernées, sauf rares exceptions.
                    <br/><br/>
                    Il s’agit de toutes les entreprises, quel que soit leur régime fiscal, qui tiennent leur comptabilité de façon informatisée.
                    <br/><br/>
                    Ne sont exclues que les entreprises qui tiennent leur comptabilité manuellement ou les entreprises agricoles soumises au forfait.
                    <br/><br/>
                    Les SCI sont tenues de fournir un FEC en cas de vérification de comptabilité dès lors qu’elles tiennent leurs documents comptables sous une forme informatisée. Par mesure de tolérance, les SCI soumises exclusivement aux revenus fonciers et qui ne comportent que des associés personnes physiques sont dispensées de présenter un FEC.
                    <br/><br/>
                    Les entreprises relevant d’un régime micro-entreprises doivent remettre un FEC au début de la vérification de comptabilité si leurs documents comptables sont établis au moyen d’un système informatisé.
                    <br/><br/>
                    Il s’agit de permettre à l’administration fiscale de gagner en efficacité lors des contrôles fiscaux et qui souhaite s’assurer que les contribuables n’utilisent pas l’informatique pour altérer, modifier, voire refaire leur comptabilité ou contourner les règles fiscales.
                    <br/><br/>
                    </p>
                </div>
            </div>
            <hr className="hr-line"/>
            <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Qu’est ce que l’épargne salariale ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> <br/>La participation et l’intéressement permettent de verser aux salariés de l’entreprise une prime collective en fonction des bénéfices ou des performances de l’entreprise. La participation est obligatoire pour les entreprises d’au moins 50 salariés. L’intéressement est quant à lui facultatif mais il permet plus de souplesse. Ces sommes ont vocation à être affectées à un plan d’épargne d’entreprise (PEE) ou un plan d’épargne pour la retraite collectif (PERCO). <br/><br/></p>
                </div>
            </div>

            <hr className="hr-line"/>
                <div className="faq-four">
                    
                    <h1 className="faq-page has-text-left" onClick={handleClick}>Pourquoi utiliser l’épargne salariale ?</h1>
                    
                        <div className="faq-body">
                            <p className="has-text-left">
                            <h1 className="title is-5">
                            <br/><br/>    
                            Un outil d’implication et de motivation des salariés</h1>

                            L’épargne salariale permet d’avoir une politique de rémunération attrayante à moindre coût et elle participe à la fidélisation des salariés. Son efficacité est d’autant plus importante que l’entreprise est de petite taille. En effet, le salarié a une meilleure visibilité de l’impact de son travail sur les performances de l’entreprise dans les TPE/PME.
                            <br/><br/>
                            <h1 className="title is-5">Des avantages sociaux et fiscaux</h1>
                        
                            Les sommes versées au titre de la participation et de l’intéressement, ainsi que les éventuels abondements de l’employeur au PEE et au PERCO, bénéficient d’avantages sociaux et fiscaux renforcés.
                            <br/><br/>
                            En matière de charges sociales, elles ne sont assujetties qu’à la CSG et à la CRDS (9,70 %) due par les salariés. En effet, depuis le 1er janvier 2019, le forfait social (contribution patronale au taux de 20 %) est supprimé dans les entreprises de moins de 50 salariés pour l’ensemble des dispositifs (participation, intéressement, abondements au PEE et au PERCO).
                            <br/><br/>
                            L’exonération du forfait social est également applicable, depuis la même date, dans les entreprises de 50 à moins de 250 salariés uniquement sur les sommes versées au titre de l’intéressement.
                            <br/><br/>
                            En outre, pour les salariés, une exonération d’impôt sur le revenu est applicable si les sommes sont placées sur un PEE ou un PERCO pendant une certaine durée.
                            <br/><br/>
                            Enfin, pour les employeurs, les sommes attribuées sont déductibles du bénéfice imposable de l’entreprise.
                            <br/><br/>
                            <h1 className="title is-5">Un dispositif ouvert au chef d’entreprise et à son conjoint</h1>
                            Dans les entreprises de 1 à 250 salariés, le chef d’entreprise (ou dirigeant) et son conjoint s’il est collaborateur ou associé, peuvent bénéficier de l’épargne salariale, ainsi que des mêmes avantages sociaux et fiscaux. Il faut toutefois veiller à le préciser dans l’accord de participation et d’intéressement.
                            <br/><br/>
                            Cet avantage doit prochainement être étendu aux personnes liées au chef d’entreprise par un Pacs. <br/>
                            </p>
                        </div>
                </div>

                <div className="titleFAQ">
                    <br/><br/><br/>
                    <h1 className="title has-text-left">Comptabilité</h1>
                </div>
                <br/><br/>
                <div className="faq-one">
            
                <h1 className="faq-page has-text-left" onClick={handleClick}>Prélèvement à la source : pourquoi et comment actualiser votre taux ?</h1>
    
                <div className="faq-body">
                    <p className="has-text-left"> 
                    <br/>
                        <h1 className="title is-5 ">À la hausse</h1>
                        
                        Aucune condition n’est exigée. Cela permet d’adapter immédiatement le taux à vos revenus prévisionnels 2019 et cela vous évitera une régularisation importante à compter de septembre 2020 quand l’impôt sur le revenu 2019 sera liquidé. Cela concerne les personnes qui n’avaient pas travaillé toute l’année en 2017, qui travaillaient à temps partiel en 2017 et qui travaillent désormais à temps complet, ainsi que celles qui ont bénéficié de promotion ou d’augmentation de salaire significative. Sont également concernés les contribuables qui avaient bénéficié de déductions en 2017 et qui ne les auront plus en 2019. Par exemple : option pour les frais réels en 2017 non reconduite en 2019, déduction de pensions alimentaires pour enfant majeur qui travaille désormais en 2019 et pour lequel on ne pourra plus déduire de pension alimentaire sur 2019.
                        <br/><br/>
                        <h1 className="title is-5 ">À la baisse</h1>
                        La différence entre le montant du prélèvement prévu et celui estimé doit être supérieure à 10 % ou 200 € annuels.
                        <br/>
                        Une modulation à la baisse excessive et non justifiée génèrera l’application de pénalités.
                        <br/>
                        Aucune modulation n’est envisageable pour tenir compte de réductions ou crédits d’impôt à venir. Il s'agit des personnes qui travaillent désormais à temps partiel ou qui sont partis à la retraite ou demandeurs d’emploi. Cela peut également concerner les personnes relevant du régime Affection de Longue Durée (ALD).  Si, en 2017, ces personnes avaient perçu des revenus « normaux » et qu’elles savent qu’en 2019, elles ne vont percevoir que des indemnités journalières de la Sécurité sociale non imposables car versées dans le cadre d’une ALD. Elles ont intérêt à informer l’administration fiscale pour que celle-ci recalcule le taux du PAS sur la base de ces nouvelles informations.
                        <br/><br/>
                    </p>
                </div>
            </div>
            <hr className="hr-line"/>
            <div className="faq-two">
               
                <h1 className="faq-page has-text-left" onClick={handleClick}>Prélèvement à la source : changement de situation ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> <br/>
                    Mariage, PACS, décès, divorce, rupture du PACS, naissance, adoption ou recueil d’un enfant mineur, tout chagement de situation est à signaler à l'administration fiscale.
                    <br/><br/>
                    1. <strong>Événements survenus en 2018 :</strong> les changements de situation familiale intervenus en 2018 pourront être déclarés à compter du 2 janvier 2019.
                    <br/> <br/>
                    2. <strong>Événements survenus à compter du 1er janvier 2019 : </strong>vous devrez informer l’administration fiscale dans les 60 jours qui suivent l’événement. Ce délai est porté à 3 mois après le prononcé d’un divorce.
                    <br/><br/>
                    À noter : aucune pénalité n’est prévue si la déclaration n’est pas faite dans les délais. L’administration fiscale disposera ensuite d’un délai de 3 mois pour actualiser votre taux et, le cas échéant, vos acomptes et informer les tiers collecteurs (employeur, caisses de retraite, Pôle emploi, etc.).
                    </p>
                </div>
            </div>
            <hr className="hr-line"/>
            <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Rappels pratiques : la facture</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                    <br/>Toute dépense intégrée dans une comptabilité doit obligatoirement être justifiée par une facture.
                        <br/><br/>
                        La facture produite doit obligatoirement mentionner les informations suivantes:
                        <br/><br/>
                        - Date<br/>
                        - Numéro de facture<br/>
                        - Nom, adresse et n°SIRET de l'émetteur<br/>
                        - Nom et adresse professionnelle du client<br/>
                        - La quantité et la dénomination précise de ce qui a été acheté<br/>
                        - Le montant Hors-Taxes des achats<br/>
                        - Le montant TTC des achats<br/>
                        <br/>
                        <strong>Attention !</strong><br/>
                        Un ticket de caisse, un ticket de carte bancaire, un devis, un bon de commande, un relevé de compte, un bon de livraison, un billet de train ne sont pas de des factures!
                    <br/><br/></p>
                </div>
            </div>



            <div className="titleFAQ">
                    <br/><br/><br/>
                    <h1 className="title has-text-left">Juridique</h1>
                </div>
                <br/><br/>
                <div className="faq-one">
            
                <h1 className="faq-page has-text-left" onClick={handleClick}>Qu’est-ce-que la déclaration des bénéficiaires effectifs ?</h1>
    
                <div className="faq-body">
                    <p className="has-text-left"> 
                    <br/>
                    Toutes les sociétés soumises à immatriculation au RCS doivent déposer au Greffe du Tribunal de Commerce un document désignant les bénéficiaires effectifs de la société.

                    Le bénéficiaire effectif est la ou les personne(s) physique(s) qui :

                        Détiennent plus de 25% du capital ou des droits de vote, directement ou indirectement,
                        Ou exercent un pouvoir de contrôle sur les organes de gestion, d’administration ou de direction de la société lors de l’assemblée générale de ses associés.

                    Ce document relatif aux bénéficiaires effectifs est adressé au RCS mais il n’est pas publié. Il peut toutefois être communiqué à différentes autorités dans le cadre de l’exercice de leur mission : administration fiscale, autorités judiciaires, bâtonniers, membres du comité anti-blanchiment de l’Ordre des Experts-Comptables.

                    Dans l’hypothèse où les informations (adresses personnelles) à la déclaration des bénéficiaires effectifs différeraient de celles figurant en Kbis de votre société, il faut procéder simultanément à la modification du Kbis.

                    Attention, le fait de ne pas déposer au Greffe du Tribunal de Commerce ce document ou de déposer un document comportant des informations inexactes ou incomplètes est répréhensible pénalement.
                    </p>
                </div>
            </div>
            <hr className="hr-line"/>
            <div className="faq-two">
               
                <h1 className="faq-page has-text-left" onClick={handleClick}>Comment créer mon entreprise ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> <br/>
                    Vous souhaitez créer votre entreprise, mais vous ne savez pas quel statut choisir?

En effet, il existe plusieurs formes de sociétés civiles. Elles ont vocation à permettre la gestion d’un patrimoine immobilier dans le cadre des SCI (société civile immobilière), de gérer la mise en commun de moyens dans le cadre d’une SCM, ou de titres dans le cadre d’une société de portefeuille ou de holding. De même, les sociétés commerciales, peuvent prendre différentes formes: SARL, EURL, SAS, SA, SNC, etc.
                    </p>
                </div>
            </div>
            <hr className="hr-line"/>
            <div className="testCenter">
                <h1 className="title is-4 has-text-left">Dépôt et publicité des comptes annuels</h1>
            </div>
            <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Qui doit déposer ses comptes annuels ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                    Seules les sociétés commerciales ont l’obligation de déposer leurs comptes annuels au greffe du tribunal de commerce. Les sociétés civiles ne sont pas soumises à cette obligation.

                    Les sociétés visées par l’obligation de dépôt des comptes sont :

                    les sociétés par actions (SA et SAS notamment) ;
                    les SARL, y compris les EURL ;
                    les SNC composées uniquement entre des SARL et/ou des sociétés par actions.
                    Les sociétés doivent déposer leurs documents comptables dans un délai d’un mois suivant l’approbation des comptes par l’assemblée générale ordinaire. Cependant, ce délai est porté à deux mois lorsque le dépôt est réalisé par voie électronique.

                    L’assemblée générale d’approbation des comptes doit être tenue dans un délai de 6 mois après la clôture de l’exercice.

                    Les entreprises individuelles ne sont pas tenues de déposer leurs comptes annuels auprès du greffe du tribunal de commerce.

                    Les EIRL ont toutefois l’obligation de déposer leurs comptes annuels chaque année au registre où a été déposée la déclaration d’affectation du patrimoine.
                    </p>
                </div>
                <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Quelle confidentialité pour la publication des comptes de ma société ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                    Les comptes annuels des sociétés commerciales inscrites au RCS doivent toujours être déposés auprès du Greffe du Tribunal de Commerce.

                    En revanche, certaines sociétés peuvent bénéficier de la confidentialité de ce dépôt. Autrement dit, certains tiers de la société ne pourront pas avoir accès aux comptes.

                    Deux mesures de confidentialité existent :

                    Une confidentialité « totale » pour les « micro-entreprises » (remplissant au moins 2 des 3 critères suivants) :

                    Total de bilan : moins de 350 000 €
                    Montant net du CA : moins de 700 000 €
                    Moins de 10 salarié
                    Les comptes sont déposés au greffe avec une option de confidentialité signée par le gérant/président. Ils ne peuvent pas être consultés par les tiers (sauf autorités administratives et judiciaires, Banque de France).

                    ATTENTION : sont cependant exclues de cette possibilité les sociétés dont l’activité consiste à gérer des titres de participation (holding) ou des valeurs mobilières, les établissements financiers, les entreprises d’assurances et de mutuelle, les sociétés cotées.

                    Une confidentialité « partielle » pour les « petites entreprises » (remplissant au moins 2 des 3 critères suivants) :

                    Total de bilan : moins de 4 000 000 €
                    Montant net du CA : moins de 8 000 000 €
                    Moins de 50 salariés
                    Les comptes sont déposés au greffe et seul le compte de résultat est confidentiel et ne peut pas être consulté par les tiers. Certaines personnes morales pourront toutefois avoir accès au compte de résultat (autorités judiciaires, administratives et la Banque de France).

                    ATTENTION : sont exclues du dispositif de confidentialité partielle les sociétés appartenant à un groupe de sociétés au sens de l’article L 233-16 du Code de commerce
                    </p>
                </div>
                <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Quels documents comptables faut-il déposer ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                    Les documents comptables qui doivent être déposés sont :

                    les comptes individuels comprenant le bilan, le compte de résultat et l’annexe ;
                    N.B. : les micro-entreprises (définies ci-après) sont dispensées d’établir l’annexe. Par ailleurs, les petites entreprises peuvent établir leur bilan, leur compte de résultat et leur annexe sous forme simplifiée.

                    le rapport du commissaire aux comptes (CAC) sur les comptes annuels ;
                    le rapport du conseil de surveillance (sociétés par actions ayant un directoire) ;
                    la proposition d’affectation du résultat et la résolution votée.
                    Dans les sociétés cotées, le dépôt doit également contenir le rapport de gestion.

                    

                    Par ailleurs, les sociétés tenues d’établir des comptes consolidés (sous contrôle exclusif ou conjoint) doivent également déposer au greffe :

                    les comptes consolidés ;
                    le rapport sur la gestion du groupe ;
                    le rapport des CAC sur les comptes consolidés.
                    

                    Le dépôt des comptes au greffe a pour effet de rendre les comptes publics, c’est-à-dire consultables par toute personne.</p>
                </div>
            </div>
           
            
                    </div>
                </div>

            <div className="testCenter">
                <h1 className="title is-4 has-text-left">Délais de paiement entre professionnels</h1>
            </div>
            <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Quels sont les délais de paiement à retenir?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                    <br /> Il existe deux situations :

                    lorsqu'aucune disposition n’est prévue dans le contrat, le délai de règlement est fixé à 30 jours après la date de réception des marchandises ou d’exécution de la prestation de service
                    lorsque le délai de paiement est effectivement prévu dans le contrat, celui-ci ne peut excéder 60 jours à compter de la date d’émission de la facture ou 45 jours fin de mois.
                    Remarque : lorsqu’un délai a été prévu par le contrat, il doit obligatoirement figurer sur la facture et dans les conditions générales de vente.</p>
                </div>
                </div>

                <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Existe-t-il des délais dérogatoires ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                     <br /> Des délais de paiement spécifiques peuvent exister dans certains secteurs comme les boissons alcoolisées, les activités saisonnières, le transport, la viande et le bétail, les marchés publics.

                    Par ailleurs, il y a également un délai dérogatoire de 90 jours applicable aux achats en franchise de TVA concernant les biens et services livrés hors de l’Union européenne.</p>
                </div>
                </div>

                <div className="faq-three">
                
                <h1 className="faq-page has-text-left" onClick={handleClick}>Quelles sont les obligations d'information comptable au sujet des délais de paiement ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                    Les sociétés dont les comptes sont certifiés par un commissaire aux comptes sont tenues de publier les informations sur les délais de paiement de leurs fournisseurs et de leurs clients.

                    Elles doivent figurer dans le rapport de gestion de l’exercice et sont présentées sous la forme de tableaux standardisés.

                    Cette obligation s’applique aux comptes afférents aux exercices ouverts à compter du 1er juillet 2016.</p>
                </div>
                </div>

                <div className="faq-three">

                <h1 className="faq-page has-text-left" onClick={handleClick}>Sanctions applicables en cas de non-respect des délais de paiement ?</h1>
                
                <div className="faq-body">
                    <p className="has-text-left"> 
                    En cas de retard de paiement, des pénalités sont dues pour la période comprise entre la date d’échéance et la date d’encaissement. Le taux d’intérêt appliqué doit être prévu dans les conditions générales de vente et ne peut être inférieur à 3 fois le taux d’intérêt légal. Il est appliqué sur le montant TTC de la fact²re.

                    Le débiteur doit aussi s’acquitter sur chaque facture en retard de paiement d’une indemnité pour frais de recouvrement de 40 euros au minimum.

                    De plus, ce professionnel est passible d’une amende administrative d’un montant de 75 000 euros pour une personne physique ou de 2 millions d’euros pour une personne morale.

                    Cette sanction sera notamment prononcée en cas de dépassement des délais ou d’absence de mention des pénalités de retard dans les conditions de règlement.

                    En outre, l’obtention ou la tentative d’obtention de conditions manifestement abusives de délais de paiement sous la menace de rupture de relations commerciales expose son auteur à une amende civile pouvant atteindre 5 millions d’euros.</p>
                </div>
                </div>
            
            </div>
        </div>

        
        <Footer/>
        </>
        
    );
}

export default Faq;

