import '../style/style.scss';
import {BallTriangle} from 'react-loader-spinner';
import logo from '../img/logo.png';
import loader from '../img/loader.gif';
import $ from 'jquery';

const Loading = () => {
    /*
    return(
        <>
        <div className='Loader'>
            <BallTriangle
                height="100"
                width="100"
                color='white'
                ariaLabel='loading'
                timeout={3000}
            />
        </div>
        </>
    );
    */
   
    return(
        <>
        <div className='Loader'>
            <div className='' id="t">
                <img src={logo} className="logoWidth"/>
            </div>
            <img src={loader} className="barLoader"/>
        </div>
        </>
    );
    
}


export default Loading;