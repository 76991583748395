import Footer from "../Footer";
import Header from "../Header";
import bg from '../../img/aProposBg.jpg'
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import trait from '../../img/trait-rouge.png'

const Quadra = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Connect<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Services&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Connect</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="consult-container">
            <div className="service-only-container">
                <h1 className="title is-3 has-text-centered ligne">
                Un accès total à nos outils de comptabilité, Quadra Connect.
                </h1>
                <img src={trait} alt="" />
                <p>
                <br />
                Notre service <span className="has-text-grey">Connect</span> utilise la technologie Quadratus mis en place par Cegid. En effet, cette solution vous donnera accès au dossier de comptabilité partagé de notre cabinet.<br /><br /><br />
                </p>
                
                <h1 className="title is-3 has-text-centered ligne">
                Qu’est-ce-que Quadra Connect ?
                </h1>
                <img src={trait} alt="" />
                
                <p>
                <br />
                Grâce à Quadra Connect, vous aurez accès à la totalité des fonctionnalités du progiciel du cabinet (comptabilité, gestion des immobilisations, suivi analytique, établissement de liasse fiscale et des déclarations fiscales…). Votre interlocuteur dédié est à même de vous assister. L’utilisation de cette plateforme requiert un accès internet et vous décharge des contraintes liées à la sauvegarde et à l’archivage de vos données, ainsi qu’aux opérations de clôture d’exercice.
                <br /><br /><br />
                </p>

                
                <h1 className="title is-3 has-text-centered ligne">
                Se former à Quadra Connect
                </h1>
                <img src={trait} alt="" />
               
                <p><br />Vous pouvez sur demande, auprès de votre interlocuteur privilégié au sein du Cabinet, vous former à la maîtrise de ce logiciel de comptabilité afin de gérer la tenue comptable de votre entreprise*.<br /><br /></p>
                <small><strong>* Attention : l'utilisation de ce service requiert une compétence comptable dans votre entreprise.<br /><br /><br /></strong></small>
                <button className="button is-primary is-large button-special"><NavLink exact to='/formulaire' className="has-text-white">Ce service m'intéresse</NavLink></button>
            </div>
            </div>
        <Footer/>
        </>
    );
}

export default Quadra;