import { Component, useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import logo from '../img/logo.png';
import '../style/style.scss';
import { NavLink } from 'react-router-dom'
import $ from "jquery";
import { useNavigate  } from "react-router-dom";

const Header = () => {
    useEffect(function () {
        var header = $(".navbar");
        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();
            if (scroll > 0) {
                header.addClass("headerScroll");
            } else {
                header.removeClass("headerScroll");
            }
        });
          
    });
    const [menuClicked,setmenuClicked] = useState(false);
    const handleClick = event => {    
        event.preventDefault();    
        event.currentTarget.classList.toggle("is-active");
        setmenuClicked((menuClicked) => !menuClicked);
    }
    return(
            <>
                <nav className="navbar is-danger is-fixed-top" role="navigation" aria-label="main navigation">
                        <div className="navbar-brand">
                            <a className="navbar-item" href="/">
                            <img src={logo} id="logo"/>
                            </a>
                            <a role="button" className="navbar-burger" onClick={handleClick} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div className={`navbar-menu ${menuClicked ? "is-active" : ""}`}>
                            <div className="navbar-end">
                                <a className="navbar-item" href="/"><strong>Accueil</strong></a> 
                                    <div className="navbar-item has-dropdown is-hoverable">
                                    <NavLink exact to='/a-propos' className="navbar-link"><strong>A Propos</strong></NavLink>
                                    <div className="navbar-dropdown">
                                                    <NavLink exact to='/a-propos/equipe' className="navbar-item">L'équipe</NavLink>
                                    </div>
                                    </div>
                                    <NavLink exact to='/missions' className="navbar-item"><strong>Missions</strong></NavLink>
                                    <div className="navbar-item has-dropdown is-hoverable">
                                    <NavLink exact to='/services/services' className="navbar-link"><strong>Services</strong></NavLink>
                                    <div className="navbar-dropdown">
                                                    <NavLink exact to='/services/consultation' className="navbar-item">QuadraWeb</NavLink>
                                                    <NavLink exact to='/services/dossier-paies' className="navbar-item">Paie</NavLink>
                                                    <NavLink exact to='/services/quadra-connect' className="navbar-item">Connect</NavLink>
                                                    <NavLink exact to='/services/box' className="navbar-item">Box</NavLink>
                                                    <NavLink exact to='/services/devis-fact' className="navbar-item">DevisFact</NavLink>
                                                    <NavLink exact to='/services/people-doc' className="navbar-item">PeopleDoc</NavLink>
                                    </div>
                                    </div>
                                    <NavLink exact to='/plan' className="navbar-item"><strong>Plan</strong></NavLink>
                                    <div className="navbar-item has-dropdown is-hoverable">
                                                    <a className="navbar-link"><strong>Plus</strong></a>
                                                    <div className="navbar-dropdown">
                                                        <NavLink exact to='/carriere' className="navbar-item">Carrière</NavLink>
                                                        <NavLink exact to='/liens-utiles' className="navbar-item">Liens Utiles</NavLink>
                                                        <NavLink exact to='/faqs' className="navbar-item">F.A.Q</NavLink>
                                                        <hr className="navbar-divider"/>
                                                        <NavLink exact to='/administration' className="navbar-item admin">Admin</NavLink>
                                                    </div>

                                    </div>
                                    <div className="navbar-item">
                                        <div className="buttons">
                                            <NavLink exact to='/mes-services' className="button is-dark has-text-white"><strong>Accéder à mes services</strong></NavLink>
                                            <a className="button is-primary">
                                            <NavLink exact to='/formulaire' className="has-text-white"><strong>Prendre RDV</strong></NavLink>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </nav>
            </>
        );
    
}
export default Header;