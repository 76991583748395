import Footer from "../Footer";
import Header from "../Header";
import bg from '../../img/aProposBg.jpg'
import trait from '../../img/trait-rouge.png'
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

const Devis = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white' id="big2">DevisFact<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Services&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;DevisFact</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="consult-container">
            <div className="service-only-container">
                <h1 className="title is-3 has-text-centered ligne">
               Le service 100% web de gestion des devis et de la facturation
                </h1>
                <img src={trait} alt="" />
                <p>
                <br />
                Notre cabinet comptable met à votre disposition le service DevisFact mis en place par Quadratus. Cette solution vous permettra d'avoir une gestion complète du devis au règlement
et un suivi facilité pour votre portefeuille.<br /><br /><br />
                </p>
                
                <h1 className="title is-3 has-text-centered ligne">
                Qu’est-ce-que DevisFact ?
                </h1>
                <img src={trait} alt="" />
                
               <p>
                <br />
               Ce dispositif a l’avantage d’être accessible sur tous les supports : ordinateur, tablette et smartphone. <br /> Il y a des mises à jour et sauvegardes automatiques, en complément d'un hébergement sécurisé.
               <br /><br />
               </p>
                <div align="center">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/sqYFdkPdY3g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                
                <p><br /><br /><strong>Les avantages procurés par la solution DevisFact sont nombreux :</strong></p>
                <p className="has-text-black">
                    <br />
                • <span className="petit">Gérer ses devis</span>  <br />
                • <span className="petit">Gérer sa facturation</span><br />
                • <span className="petit">Suivre les règlements du début à la fin</span> <br />
                <br />

                <h1 className="title is-4">Pilotage de l'activité simplifié grâce au tableau de bord</h1>
             

                • <span className="petit">Tableau de bord dynamique avec indicateurs visuels : chiffre d’affaires, marge, devis et règlements en retard à relancer</span>  <br />
                • <span className="petit">Historique, actions réalisées et accès rapide aux différentes pièces ou menus</span><br />
              


                <h1 className="title is-4"><br /> Gestion de la facturation & Suivi des règlements</h1>

                • <span className="petit"> Impression et envoi de la facture directement depuis l’outil</span>  <br />
                • <span className="petit">Relances facilitées : indicateurs visuels qui vous alertent pour toute facture ayant dépassé la date de règlement</span><br />
                • <span className="petit">  Enregistrement de vos règlements par pointage partiel ou complet de vos factures</span>  <br />
                • <span className="petit">  Transfert des journaux de vente et d’encaissement vers le cabinet à tout moment grâce au bouton « exporter »</span>  <br />


                <h1 className="title is-4"><br /> Gestion des devis simplifiée</h1>

                • <span className="petit"> Création intuitive et duplication facilitée des devis</span>  <br />
                • <span className="petit">Gestion simplifiée des devis par état d’avancement :
                    En cours, Imprimé, Accepté, Refusé</span><br />

                <strong><br /> Gagnez en flexibilité dans les tâches administratives :</strong>

                <br /><br /> • <span className="petit"> La fonctionnalité « brouillons » permet d’ouvrir une première estimation à un prospect sans ajout préalable dans la base de données clients</span><br />
                • <span className="petit"> Création rapide d’un nouvel article lors de l’établissement d’un devis</span><br />

                </p>
                

                
          



                
                <h1 className="title is-3 has-text-centered ligne">
                <br /> Se former à DevisFact
                </h1>
                <img src={trait} alt="" />
               
                <p><br />Vous pouvez sur demande, auprès de votre interlocuteur privilégié au sein du Cabinet, vous former à la maîtrise de ce logiciel de comptabilité afin de gérer la tenue comptable de votre entreprise.<br /><br /></p>
                
                <button className="button is-primary is-large button-special"><NavLink exact to='/formulaire' className="has-text-white">Ce service m'intéresse</NavLink></button>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Devis;