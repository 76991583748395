import bg from '../img/aProposBg.jpg';
import Footer from './Footer';
import Header from './Header';
import '../style/timeline.scss';
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

import imgAPropos from '../img/logo2.png';
import maps from '../img/maps.png';
import timeline1 from '../img/timeline-1.jpg';
import timeline2 from '../img/timeline-2.jpg';
import timeline3 from '../img/timeline-3.jpg';
import timeline4 from '../img/timeline-4.jpg';
import user from "../img/Luc.webp";
import femme from "../img/femme.jpg";
import homme from "../img/homme.jpg";

const Apropos = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">A propos<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;A Propos</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className='aPropos-container' id="trueApropos">
            <img src={imgAPropos} id="imgAPropos2" className='image'/>
            <div className='aPropos-text has-text-justified'>
                <p className='has-text-primary'>Qui sommes-nous ?</p>
                <h1 className='title is-2 move'>Le Cabinet SECLS</h1>
                <p><strong> Le cabinet SECLS est une société d’expertise comptable implantée à Marly-le-Roi (dans le département des Yvelines) depuis 1958.</strong></p>
                <br/>
                <p>

                    Il est dirigé par Luc LECOMTE, Expert-comptable et Commissaire aux Comptes, entouré d’une équipe pluridisciplinaire de professionnels qui est à votre écoute pour vous conseiller, construire, développer en matière de gestion comptable, fiscale, financière et sociale.

                    Le cabinet intervient dans tous les secteurs d’activités : artisanat, commerce, industrie, professions libérales, associations.

                    Contactez-nous pour en savoir plus sur notre accompagnement et nos services.

                </p>
                <br/>
                <div align="center" id='buttonsApropos'>
                    <a href='#timeline' className="button is-primary is-rounded" id='aPropos'><strong>Voir notre histoire</strong></a>
                    <NavLink exact to='/carriere' className='button is-primary is-large is-outlined is-rounded'id='aPropos'><strong>Nous rejoindre</strong></NavLink>
                </div>
            </div>
        </div>
        <section id="timeline">
            <h1 class="title is-1"><br/>Notre Histoire</h1>
            <p class="leader">Apprenez à nous connaître au travers de ces dates clés.</p>
            <div class="demo-card-wrapper">
                <div class="demo-card demo-card--step1">
                    <div class="head">
                        <div class="number-box">
                            <span>1958</span>
                        </div>
                        <h2><span class="small"></span>Création</h2>
                    </div>
                    <div class="body">
                        <p>Fondation en 1958 par M. SAUVIAT</p>
                        <img src={timeline1}  alt="Graphic"/>
                    </div>
                </div>


                <div class="demo-card demo-card--step4">
                    <div class="head">
                        <div class="number-box">
                            <span>1995</span>
                        </div>
                        <h2><span class="small"></span>Passation</h2>
                    </div>
                    <div class="body">
                        <p>Reprise du Cabinet par M. LECOMTE</p>
                        <img src={timeline3} alt="Graphic"/>
                    </div>
                </div>

                <div class="demo-card demo-card--step5">
                    <div class="head">
                        <div class="number-box">
                            <span>2022</span>
                        </div>
                        <h2><span class="small"></span>Présent</h2>
                    </div>
                    <div class="body">
                        <p>Aujourd'hui, nous aidons nos clients à developper leurs entreprises avec nos solutions à la pointe de la technologie. Notre mot d'ordre : l'adaptation.</p>
                        <img src={maps} alt="Graphic"/>
                    </div>
                </div>
            
            </div>
        </section>

        <div className='container-equipe'>
            <p className='has-text-primary has-text-centered'>Qui Sommes-nous ?</p>
            <h1 className='title is-2 has-text-centered'>L'Équipe SECLS</h1>
            
            <div className="container-details">
                <h1 className="title is-4">Luc LECOMTE</h1>
                <p className="">Expert-Comptable / Commissaire Aux Comptes</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:luc.lecomte@secls.fr"> luc.lecomte@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.39</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Clément MILLET</h1>
                <p className="">Expert-Comptable Mémoraliste</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:clement.millet@secls.fr"> clement.millet@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.01</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Aurélie HECQUET</h1>
                <p className="">Secrétariat et Juridique</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:contact@secls.fr"> contact@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.39</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Alexia FAMIN</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:alexia.famin@secls.fr"> alexia.famin@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.67</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Corinne SAUVIAT</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:corinne.sauviat@secls.fr"> corinne.sauviat@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.16</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Etienne SAUVIAT</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:etienne.sauviat@secls.fr"> etienne.sauviat@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.74</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Nathalie SAMAKE</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:nathalie.samake@secls.fr"> nathalie.samake@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.11</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Olivier CHENEAU</h1>
                <p className="">Service Comptable</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:olivier.cheneau@secls.fr"> olivier.cheneau@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.70.45</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Isabelle JANY</h1>
                <p className="">Service Social</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:isabelle.jany@secls.fr"> isabelle.jany@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.78.64.95.10</p>
            </div>
            <div className="container-details">
                <h1 className="title is-4">Sylvie LECOMTE</h1>
                <p className="">Service Social</p>
                <p className="has-tex-left"><br /> <i class="fa-solid fa-envelope"></i> <a href="mailto:sylvie.lecomte@secls.fr"> sylvie.lecomte@secls.fr</a></p>
                <p className="has-tex-left"><i class="fa-solid fa-phone"></i> 01.39.58.71.20</p>
            </div>


        </div>
        <Footer/>
        </>
    );
}

export default Apropos;