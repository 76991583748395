import logo from '../img/logo.png';
import logo2 from '../img/logo2.png';
import '../style/style.scss';
import $ from 'jquery';

import { NavLink } from 'react-router-dom';

const Footer = () => {
    $(document).ready(function () {
        $(window).scroll(function(){
            var distance = $(window).scrollTop();
            var scrollButton = $(".scrollUp");
            if( distance > 0){
                scrollButton.addClass("scroll-active");
            }
            else{
                scrollButton.removeClass("scroll-active");
            }
        });
    });

    return(
        <>
            <footer class="footer">
                
                <div class="content has-text-centered has-text-white">
                    
                    <div class="columns ">

                    <div class="column">
                        <h6 className='styleFooter'>Navigation</h6>
                        <ul>
                        <li><a href="/">Accueil</a></li>
                        <li><NavLink exact to='/services/services'>Services</NavLink></li>
                        <li><NavLink exact to='/a-propos'>A Propos</NavLink></li>
                        <li><NavLink exact to='/plan'>Plan</NavLink></li>
                        <li><a href="#">Actualités</a></li>
                        
                        </ul>
                    </div>

                    <div class="column">
                        <h6>En savoir plus</h6>
                        <ul>
                        <li><NavLink exact to='/carriere'>Carrière</NavLink></li>
                        <li><NavLink exact to='/liens-utiles'>Liens Utiles</NavLink></li>
                        <li><NavLink exact to='/faqs'>F.A.Q</NavLink></li>
                        <li><NavLink exact to='/administration'>Admin</NavLink></li>
                        </ul>
                    </div>

                    <div class="column">
                        <h6>Services</h6>
                        <ul>
                        <li><NavLink exact to='/services/consultation'>QuadraWeb</NavLink></li>
                        <li><NavLink exact to='/services/dossier-paies'>Paie</NavLink></li>
                        <li><NavLink exact to='/services/quadra-connect'>Connect</NavLink></li>
                        <li><NavLink exact to='/services/box'>Box</NavLink></li>
                        <li><NavLink exact to='/services/devis-fact'>DevisFact</NavLink></li>
                        <li><NavLink exact to='/services/people-doc'>PeopleDoc</NavLink></li>
                        </ul>
                    </div>

                    <div class="column">
                        <h6>Contact</h6>
                        <ul>
                        <li class="has-text-black"><i class="fa-solid fa-phone"></i> 01.39.58.70.39</li>
                        <li><a href="mailto:contact@secls.fr"> <i class="fa-solid fa-envelope has-text-black"></i>  contact@secls.fr</a></li>
                        <h6><br/>Horaires</h6>
                        <p class="has-text-black">Du lundi au vendredi de 8h30 à 12h00 et de 13h30 à 18h00</p>
                        <p class="has-text-black">12 Av. de Saint-Germain<br/>78160 Marly-le-Roi</p>
                        </ul>
                    </div>

                    <div class="column">
                        <ul>
                            <h6>Newsletter</h6>
                            <div class="control has-icons-left has-icons-right">
                            <input class="input is-medium" type="email" placeholder="Email"/>
                            <span class="icon is-left">
                            <i class="fa fa-envelope"></i>
                            </span>
                            <small class="has-text-black">Le Cabinet SECLS ne vendra jamais vos données à un tiers.</small>
                            </div>
                            <a class="button is-primary is-rounded" id="newsLetter">S'abonner</a>
                        </ul>
                    </div>
                    </div>
                    <a href='#'><img id="logoEnd" src={logo2}/></a>
                </div>
                </footer>
                <div class="has-background-primary bottom">
                <div class="container is-fluid">
                    <div class="columns">
                    <div className='column'>
                        <p class="has-text-centered"><NavLink exact to='/mentions-legales' className="has-text-black">Politique de Confidentialité</NavLink></p>
                    </div>
                    <div class="column ">
                        <p className="has-text-centered has-text-black">©2022 cabinet-secls.fr - Tous droits réservés.</p>
                        
                    </div>
                    <div className='column'>
                        <p class="has-text-centered">Création par <a href="https://remydionisio.fr">Rémy Dionisio</a></p>
                    </div>
                    </div>
                </div>
                </div>
                <a class="scrollUp" href="#"> <i class="fas fa-arrow-up"></i> </a>    
        </>
    );
}

export default Footer;