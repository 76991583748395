import Footer from "../Footer";
import Header from "../Header";
import bg from '../../img/aProposBg.jpg'
import trait from '../../img/trait-rouge.png'
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

const Consultation = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white' id="big2">Web<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Services&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Web</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="consult-container">
            <div className="service-only-container">
                <h1 className="title is-3 has-text-centered ligne">
                Notre Cabinet met à la disposition de ses clients la solution sécurisée Quadra Web Services
                </h1>
                <img src={trait} alt="" />
                <p>
                <br />
                Notre service <span className="has-text-grey">Web</span> utilise la technologie Quadratus mise en place par Cegid. En effet, cette solution vous permet d’avoir un regard permanent sur votre gestion comptable, depuis un espace totalement sécurisé.<br /><br /><br />
                </p>
                
                <h1 className="title is-3 has-text-centered ligne">
                Qu’est-ce-que Quadra Web Services ?
                </h1>
                <img src={trait} alt="" />
                
                <p>
                <br />
                Grâce à Quadra Web Services, accédez à vos données comptables à tout moment. Vous pilotez ainsi votre entreprise en toute sécurité, et en lien permanent avec votre cabinet d’expertise-comptable SECLS.
                <br /><br /><br />
                </p>
                
                <div align="center">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/IhdgF67D4Yc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                
                <p><br /><br /><strong>Les avantages procurés par la solution Quadra Web Services sont nombreux :</strong></p>
                <p className="has-text-black">
                    <br />
                • <span className="petit">L’enregistrement en temps réel de vos données comptables de manière intuitive et conviviale</span>  <br />
                • <span className="petit">La consultation de votre dossier comptable en ligne à tout moment 24h/24, 7j/7</span><br />
                • <span className="petit">La dématérialisation de vos documents (GED)</span> <br />
                <br />

                A tout moment, vous pouvez effectuer les opérations suivantes :
                <br /><br />

                • <span className="petit">Consultation, lettrage et rapprochement des comptes</span>  <br />
                • <span className="petit">Gestion des tiers : balance âgée, relances</span><br />
                • <span className="petit">Récupération et intégration des relevés bancaires</span> <br />
                • <span className="petit">Editions comptables : journaux, grand- livre, balances…</span> <br />
                • <span className="petit">Import / Export au format Excel</span> <br />
                • <span className="petit">Comptabilité analytique</span> <br />
                • <span className="petit">Déclaration de TVA</span> <br />

                <br />
                Vous réduisez ainsi vos coûts de suivi et gardez un œil permanent sur votre comptabilité, depuis n’importe quel poste de travail*.
                <br /><br />
                </p>
                
                <small><strong>* La solution Quadra Web Services est compatible uniquement avec les ordinateurs de type PC fonctionnant sous Microsoft Windows Vista, 7, 8 et 10 à jour de leurs Services Packs et dernières mises à jour publiées sur WindowsUpdate.<br /><br /><br /></strong>
                </small>
                
                <h1 className="title is-3 has-text-centered ligne">
                Se former à Quadra Web Services
                </h1>
                <img src={trait} alt="" />
               
                <p><br />Vous pouvez sur demande, auprès de votre interlocuteur privilégié au sein du Cabinet, vous former à la maîtrise de ce logiciel de comptabilité afin de gérer la tenue comptable de votre entreprise.<br /><br /></p>
                
                <button className="button is-primary is-large button-special"><NavLink exact to='/formulaire' className="has-text-white">Ce service m'intéresse</NavLink></button>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Consultation;