import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg';
import { useEffect, useState } from "react";
import Axios from "axios";
import { Navigate, useNavigate  } from "react-router-dom";

const Admin = (props) =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("");

    const handleUser = (event) => {
        event.preventDefault();
        setUser(event.target.value);
        console.log(user);
    }
    const handlePwd = (event) => {
        event.preventDefault();
        setPwd(event.target.value);
        console.log(pwd);
    }

    const createAccount = (event) => {
        event.preventDefault();
        Axios.post("https://cabinet-secls.herokuapp.com/insert", {
            user : user,
            pwd: pwd
        });
    }
    let history = useNavigate();
    const checkUser = (event) => {
        event.preventDefault();
        const element = document.querySelector("#test");
        Axios.post("https://cabinet-secls.herokuapp.com/login", {
            user : user,
            pwd: pwd
        }).then((response) => {
            
           if(response.data.state == "0"){
                element.innerHTML = "Identifiants invalides";
           }else{
                sessionStorage.setItem('token', response.data.token);
                history("/administration/panel");
            }
            
        });
    }   

    /*
    const hashPwd = (pwd) => {
        var algo = "sha256";
        var format = "hex";
        var secret = context.values.get("HASH_SECRET");
        return utils.crypto.hmac(pwd, secret, algo, format);
    }
    */

    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Administration<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;En savoir plus&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Admin</a></li>
                    </ul>
                </nav>
            </div>
        </div>

        <div className="jobs-container" align="center"  id="formContact2">
            <h1 className="title">Me Connecter</h1>
        <form  method="POST" class="field lenField" onSubmit={checkUser}>
                
        <div >
                        <label class="label"><br /> Nom d'utilisateur <span className="has-text-primary">*</span></label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" onChange={handleUser} placeholder="Nom d'utilisateur" name="user" required/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                    </div>
                    </div>

                    <div class="field">
                        <label class="label"><br/>Mot de Passe <span className="has-text-primary">*</span></label>
                    <div class="control has-icons-left">
                        <input class="input" type="password" onChange={handlePwd} placeholder="Mot de passe" name="pwd" required/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                    </div>
                    </div>

                    <div class="field is-grouped" id="buttonForm">
                <div class="control">
                    <button class="button is-primary">Se Connecter</button>
                </div>
                <div class="control">
                    <a class="button is-primary is-light" href="/accueil">Retour</a>
                </div>
                </div>
        </form>

        <p id="test"></p>

        
        </div>
        <Footer/>
        </>
    );
}

export default Admin;