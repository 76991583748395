import Footer from "../Footer";
import Header from "../Header";
import bg from '../../img/aProposBg.jpg'
import trait from '../../img/trait-rouge.png'
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

const Box = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white' id="big2">Box<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Services&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Box</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="consult-container">
            <div className="service-only-container">
                <h1 className="title is-3 has-text-centered ligne">
                Le service de dématérialisation de vos factures d’achats, de ventes et de vos documents professionnels
                </h1>
                <img src={trait} alt="" />
                <p>
                <br />
                Notre cabinet comptable met à votre disposition le service QuadraBox mis en place par Quadratus. Ce service 100% collaboratif de saisie comptable en ligne entre votre entreprise et notre cabinet vous permet de dématérialiser vos factures d’achats, de ventes, de relevés bancaires … et de les retrouver sur notre plateforme sécurisée. Notre cabinet détient l’ensemble des données de manière sécurisée sur le cloud privé et dispose donc d’informations essentielles pour mener à bien nos missions de conseils sur la gestion comptable de votre entreprise.<br /><br /><br />
                </p>
                
                <h1 className="title is-3 has-text-centered ligne">
                Qu’est-ce-que Quadra Box ?
                </h1>
                <img src={trait} alt="" />
                
               <p>
                <br />
               Ce dispositif a l’avantage d’être accessible sur tous les supports : ordinateur, tablette et smartphone.
               <br /><br />
               </p>
                <div align="center">
                     <iframe width="560" height="315" src="https://www.youtube.com/embed/46cl_naAHT4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                
                <p><br /><strong>Les avantages procurés par la solution Quadra Box sont nombreux :</strong></p>
               <p>
                <br />
                <h1 className="title is-4">Dématérialisation des factures</h1>

                Grâce à la reconnaissance optique des caractères, vos documents déposés par vos clients sont numérisés et intégrés à votre comptabilité. Quadra Box est en mesure d’identifier l’authenticité des factures, de repérer des dates d’échéance des contrats ou d’interpréter les postes pour l’affectation automatique les données. Vous pouvez récupérer vos différentes documents numérisés depuis un scan mobile ou par téléchargement. <br /><br />

                Cette reconnaissance automatique de caractère (OCR) limite l’intervention du client et du cabinet dans l’échange des documents comptables, factures, fichiers clients, etc. Un réel gain de temps, qui plus est sécurisé. <br />
               <br />
               </p>

               <p>
                <br />
                <h1 className="title is-4">Une plateforme sécurisée pour vos documents comptables</h1>

                Le traitement des factures clients et fournisseurs est sécurisé grâce à un outil de contrôle de doublons de factures, à une vérification de l’existence du fournisseur. Vous disposez également d’une solution analytique avec un suivi périodique. <br /><br />

                De plus, optimisez la productivité de votre entreprise facilement avec la QuadraBox. Ce service à la portée de tout le monde et facile d’utilisation ne nécessite pas de notion comptable. Une intégration en temps réelle des éléments comptables dans QuadraExpert vous fait gagner un temps inconsidérable, avec un découpage automatique d’un fichier multi-pages et un apprentissage des zones de factures non reconnues. <br />
               <br />

               Enfin cette plateforme collaborative peut être utilisée à tout moment grâce à son interface web multi-supports. <br /><br />

               </p>
                
               <p>
                <br />
                <h1 className="title is-4">L’application mobile Quadrabox</h1>

                QuadraBox est disponible sur l’App Store et sur Google Play. L’application vous permet de transférer facilement tous les documents depuis l’appareil photo de votre smartphone. L’ensemble des différentes fonctionnalité du service est accessible à tout moment, où que vous soyez. Une solution de comptabilité en ligne idéale pour vos déplacements professionnels. <br /><br />
               <br />
               </p>



                
                <h1 className="title is-3 has-text-centered ligne">
                Se former à Quadra Box
                </h1>
                <img src={trait} alt="" />
               
                <p><br />Vous pouvez sur demande, auprès de votre interlocuteur privilégié au sein du Cabinet, vous former à la maîtrise de ce logiciel de comptabilité afin de gérer la tenue comptable de votre entreprise.<br /><br /></p>
                
                <button className="button is-primary is-large button-special"><NavLink exact to='/formulaire' className="has-text-white">Ce service m'intéresse</NavLink></button>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Box;