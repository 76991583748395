import Footer from "../Footer";
import Header from "../Header";
import bg from '../../img/aProposBg.jpg'
import trait from '../../img/trait-rouge.png'
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

const Paies = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white' id="big2">Paie<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Services&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Paie</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="consult-container">
            <div className="service-only-container">
                <h1 className="title is-3 has-text-centered ligne">
                Une solution au service de votre performance et de votre compétitivité
                </h1>
                <img src={trait} alt="" />
                <p>
                <br />
                    Notre service <span className="has-text-grey">Paie</span> utilise la technologie Quadratus mise en place par Cegid. En effet, cette solution va vous permettre de devenir un pro de la paie en produisant des bulletins justes.<br /><br /><br />
                </p>
                
                <h1 className="title is-3 has-text-centered ligne">
                Le service Quadra Paie, c'est :
                </h1>
                <img src={trait} alt="" />
                
                <p>
                <br />
                <strong>1. Un assistant paie/RH</strong>
                <br/> Un assistant pour toute votre gestion RH en accord avec les conventions collectives et un accès instantané à toutes les informations dont vous avez besoin.
                <br />
                </p>

                <p>
                <br />
                <strong>2. Une conformité nationale</strong>
                <br/> Une mise à jour automatique et en temps réel des constantes nationales et des plans de paie par téléchargement. Ce service sera toujours dans les normes.
                <br />
                </p>

                
                <p>
                <br />
                <strong>3. Un logiciel en mode saas</strong>
                <br/> Accédez à tout moment au logiciel et à vos informations depuis n’importe quel navigateur. Vos données professionnelles sont hébergées en France.
                <br />
                </p>

                <p>
                <br />
                <strong>4. Une fiabilité quotidienne</strong>
                <br/> Une richesse de fonctionnalités au service de la productivité de votre entreprise et de la sécurité de votre production sociale et RH.
                <br /><br />
                </p>

                <div align="center">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/qJXljA1TWAk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                
                <p><br /><br /><strong>Les avantages procurés par la solution Quadra Paie sont nombreux :</strong></p>
                <p className="has-text-black">
                    <br />
                • <span className="petit">Limitez les erreurs : grâce à un logiciel performant, souple et simple d’utilisation.</span>  <br />
                • <span className="petit">Gain de temps : Accélérez la saisie de vos bulletins de paie et votre productivité.</span><br />
                • <span className="petit">Maitrisez vos chiffres : Une meilleure saisie et un meilleur rendement de votre activité.</span> <br />
                <br />

                Vous souhaitez utiliser Quadra Paie pour gérer vos bulletins de paie ?
                <br /><br />

                • <span className="petit">Utilisation de plan de paie type</span>  <br />
                • <span className="petit">Intégration des variables de paie par import de fichier</span><br />
                • <span className="petit">Mise à jour automatique et mensuelle</span> <br />
                • <span className="petit">Gestion de la paie conventionnelle</span> <br />
                • <span className="petit">Gestion de la participation</span> <br />
                • <span className="petit">Statistiques sociales : évolution des salariés, pyramide des âges, etc </span> <br />

                <br />
                Ce service représente un accompagnement durable où la gestion de la paie et des ressources humaines sont clés.
                <br /><br />
                </p>
                
                
                <h1 className="title is-3 has-text-centered ligne">
                Se former à Quadra Paie
                </h1>
                <img src={trait} alt="" />
               
                <p><br />Vous pouvez sur demande, auprès de votre interlocuteur privilégié au sein du Cabinet, vous former à la maîtrise de ce logiciel de comptabilité afin de gérer la tenue comptable de votre entreprise.<br /><br /></p>
                
                <button className="button is-primary is-large button-special"><NavLink exact to='/formulaire' className="has-text-white">Ce service m'intéresse</NavLink></button>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Paies;