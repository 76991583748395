import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg';
import { useEffect } from "react";

function Plan() {
    useEffect( () => {
        window.scrollTo(0, 0);
    });

    return ( 
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Plan<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Plan</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className='contact' align="center" id="marginServices">
            <div align='center'>
                <p className='has-text-primary'><br/>Comment nous joindre ?</p>
                <h1 className='title'>Nous Contacter</h1>
            </div>

            <div className='contactStructure'>
                <iframe id="google"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.2031618401375!2d2.091939216050231!3d48.87340347928888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6881c865c80a7%3A0xe4214d473cafb3df!2sS.E.C.L.S%20Soci%C3%A9t%C3%A9%20d&#39;Expertise%20Comptable%20Lecomte%20Sauviat!5e0!3m2!1sfr!2sfr!4v1655151541431!5m2!1sfr!2sfr" 
                    width="800" 
                    height="600" 
                    style={{border : 0}}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>

                <div className='textContact' >
                    <div className='columns is-flex-direction-column'>
                        <div className='column'>
                            <h1 className='title is-3'><i class="fa-solid fa-location-dot has-text-primary"></i> Adresse</h1>
                            <p class="has-text-centered">12 Av. de Saint-Germain </p>
                            <p class="has-text-centered">78160 Marly-le-Roi</p>
                        </div>
                        <div className='column'>
                            <h1 className='title is-3'><i class="fa-solid fa-envelope has-text-black has-text-primary"></i> Contact</h1>
                            <p class="has-text-centered">01.39.58.70.39</p>
                            <p class="has-text-centered"><a href="mailto:contact@secls.fr">contact@secls.fr</a></p>
                        </div>
                        <div className='column'>
                            <h1 className='title is-3'><i class="fa-solid fa-clock has-text-primary"></i> Horaires</h1>
                            <p>Du lundi au vendredi de 8h30 à 12h00 <br /> et de 13h30 à 18h00</p>
                            <p class="has-text-centered">Fermé le week-end</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        <Footer/>
        </>
     );
}

export default Plan;