import { Navigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg';
import Axios from "axios";
import { useEffect, useState } from "react";




function Panel() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [duration, setDur] = useState("");
    const [description, setDescr] = useState("");
    const [missions, setMiss] = useState("");
    const [formation, setForm] = useState("");
    const [exp, setExp] = useState("");
    const [avantages, setAvant] = useState("");
    const [horaires, setHor] = useState("");

    const handleTitle = (event) => {
        event.preventDefault();
        setTitle(event.target.value);
    }
    const handleType = (event) => {
        event.preventDefault();
        setType(event.target.value);
    }    
    const handleDur = (event) => {
        event.preventDefault();
        setDur(event.target.value);
    }
    const handleDescr = (event) => {
        event.preventDefault();
        setDescr(event.target.value);
    }
    const handleMissions = (event) => {
        event.preventDefault();
        setMiss(event.target.value);
    }
    const handleForm = (event) => {
        event.preventDefault();
        setForm(event.target.value);
    }
    const handleExp = (event) => {
        event.preventDefault();
        setExp(event.target.value);
    }
    const handleAv = (event) => {
        event.preventDefault();
        setAvant(event.target.value);
    }
    const handleHor = (event) => {
        event.preventDefault();
        setHor(event.target.value);
    }


    const createOffer = (event) => {
        event.preventDefault();
        Axios.post("https://cabinet-secls.herokuapp.com/api/insert/offer", {
            title : title,
            type: type,
            duration : duration,
            description: description,
            missions: missions,
            formation: formation,
            exp : exp,
            avantages : avantages,
            horaires : horaires
        }).then( response => {
            setOfferList([...offerList, {
                _id : response.data._id,
                title : title,
                type: type,
                duration : duration,
                description: description,
                missions: missions,
                formation: formation,
                exp : exp,
                avantages : avantages,
                horaires : horaires
            }])
        });
    }

    const deleteOffer = (id) => {
        Axios.delete(`https://cabinet-secls.herokuapp.com/api/delete/${id}`).then( () => {
            setOfferList(offerList.filter((val) => {
                return val._id != id;
            })) 
        })
    }

    const [offerList, setOfferList] = useState([]);

    useEffect(() => {
        Axios.get("https://cabinet-secls.herokuapp.com/api/read").then( (res) => {
            setOfferList(res.data);
        })
        var token = sessionStorage.getItem('token');
        if(!token){
            return <Navigate to='/administration'/>
        }
    
    }, []);



    return ( 
        <>
         <Header/>
         <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Panel<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;En savoir plus&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Administration&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Panel</a></li>
                    </ul>
                </nav>
            </div>
        </div>

        <div className="newOffer-container" align="center"  id="formContact2">
            <h1 className="title">Nouvelle Offre</h1>
        <form  method="POST" class="field lenField" onSubmit={createOffer}>
                
        <div >
                        <label class="label has-text-left"><br />Intitulé <span className="has-text-primary">*</span></label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" onChange={handleTitle} placeholder="Intitulé de l'offre" name="title" required/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                    </div>
                    </div>


                    <div class="field">
                        <label class="label has-text-left"><br /> Type de contrat <span className="has-text-primary">*</span></label>
                        <div class="control">
                            <div class="select">
                            <select name="type" onChange={handleType} required>
                                <option>Selectionner le type</option>
                                <option>CDI</option>
                                <option>CDD</option>
                                <option>Stage</option>
                                <option>Alternance</option>
                            </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left"><br/>Durée</label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" onChange={handleDur} placeholder="Durée" name="duration"/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                    </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left"><br /> Pourquoi nous rejoindre <span className="has-text-primary">*</span></label>
                        <div class="control">
                            <textarea class="textarea" placeholder="Description" onChange={handleDescr} name="description" required></textarea>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left"><br /> Missions <span className="has-text-primary">*</span></label>
                        <div class="control">
                            <textarea class="textarea" placeholder="Missions" onChange={handleMissions} name="missions" required></textarea>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left"><br/>Formation <span className="has-text-primary">*</span></label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" placeholder="Qualifications requises" name="qualif" onChange={handleForm} required/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                    </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left"><br/>Expérience requise <span className="has-text-primary">*</span></label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" placeholder="Expériences" name="experiences" onChange={handleExp} required/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                    </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left"><br /> Avantages <span className="has-text-primary">*</span></label>
                        <div class="control">
                            <textarea class="textarea" placeholder="Avantages" name="avantages" onChange={handleAv} required></textarea>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label has-text-left"><br /> Horaires <span className="has-text-primary">*</span></label>
                        <div class="control">
                            <textarea class="textarea" placeholder="Horaires" name="horaires" onChange={handleHor} required></textarea>
                        </div>
                    </div>

                    <div class="field is-grouped" id="buttonForm">
                <div class="control">
                    <button class="button is-primary">Valider</button>
                </div>
                <div class="control">
                    <a class="button is-primary is-light" href="/">Retour</a>
                </div>
                </div>
            </form>
            </div>

            <div className="modif-offer-container" align="center">
                <h1 className="title">Offre(s) actuelle(s)</h1>

                    <div className="columns container-render-offer">
                        {
                            offerList.map((val) => {
                                return (
                                <div className="column renderOffer"> 
                                    <h1 className="title is-4">{val.title}</h1>
                                    <h1 className="title is-6">{val.type}</h1> 
                                    <h1 className="title is-6">{val.date}</h1> 
                                    <button className="button is-primary" onClick={() => {deleteOffer(val._id);}}>Supprimer</button>
                                </div>
                                )
                            })
                        }
                    </div>
            </div>
         <Footer/>
        </>
     );
}

export default Panel;