import dossierPaies from '../../img/dossierPaies.png';
import consult from '../../img/consult.png';
import connect from '../../img/connect.png';
import box from '../../img/box.png';
import devis from '../../img/devis.png';
import doc from '../../img/doc.png';
import { NavLink } from 'react-router-dom'
import Header from '../Header';
import Footer from '../Footer';

import bg from '../../img/aProposBg.jpg';
import { useEffect } from "react";


function ServiceAll() {

    useEffect( () => {
        window.scrollTo(0, 0);
    });

    return ( 
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Services<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Services&nbsp;</a></li>
                    </ul>
                </nav>
            </div>
        </div>
         <div align="center" id='marginServices'>
            <p className='has-text-primary' >Nos solutions</p>
            <h1 className='title is-2'>Services</h1>
        </div>

        <div className='containerServices' align="center" id='marginServices2'>
            <div class = "columns is-variable is-4" id="services">

                <div class = "column">
                    <NavLink exact to='/services/consultation'>
                        <div class = "serviceColumn">
                            <img src={consult} />
                            <h1 className='title is-4 has-text-primary'><br/> QuadraWeb</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Comptabilité générale, États comptables et financiers, Tableaux de bord et Clôture annuelle/périodique.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/dossier-paies'>
                        <div class = "serviceColumn">
                            <img src={dossierPaies} />
                            <h1 className='title is-4 has-text-primary'><br/> Paie</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Simplissité, souplesse et sécurité. Le Cabinet SECLS prends en charge la personnalisation de vos dossiers de paie.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/quadra-connect'>
                        <div class = "serviceColumn">
                            <img src={connect} />
                            <h1 className='title is-4 has-text-primary'><br/> Connect</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Pilotez plus facilement votre activité en ayant un accès complet à l'outil de production comptable.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>
                
                </div>
                
        </div>

        <div className='containerServices' align="center" id='column2'>
            <div class = "columns is-variable is-4" id="services">

                <div class = "column">
                    <NavLink exact to='/services/box'>
                        <div class = "serviceColumn">
                            <img src={box} />
                            <h1 className='title is-4 has-text-primary'><br/> Box</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Le service de dépôt/consultation de documents comptables.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/devis-fact'>
                        <div class = "serviceColumn">
                            <img src={devis} />
                            <h1 className='title is-4 has-text-primary'><br/>DevisFact</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                L'outil de facturation qui vous simplifie la vie. Il permet d'établir des devis, factures d'accomptes, factures classiques...
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/people-doc'>
                        <div class = "serviceColumn">
                            <img src={doc} />
                            <h1 className='title is-4 has-text-primary'><br/> PeopleDoc</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Vous cherchez une solution moderne et simple pour distribuer vos bulletins de payes ?
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>
                                           
                </div>
                </div>
                <Footer/>
        </>
     );
}

export default ServiceAll;