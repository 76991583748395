import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg'
import { useEffect, useState } from "react";

const Links = () => {

    useEffect(() => {
        window.scrollTo(0, 0);  let tabsWithContent = (function () {
            let tabs = document.querySelectorAll('.tabs li');
            let tabsContent = document.querySelectorAll('.tab-content');
          
            let deactvateAllTabs = function () {
              tabs.forEach(function (tab) {
                tab.classList.remove('is-active');
              });
            };
          
            let hideTabsContent = function () {
              tabsContent.forEach(function (tabContent) {
                tabContent.classList.remove('is-active');
              });
            };
          
            let activateTabsContent = function (tab) {
              tabsContent[getIndex(tab)].classList.add('is-active');
            };
          
            let getIndex = function (el) {
              return [...el.parentElement.children].indexOf(el);
            };
          
            tabs.forEach(function (tab) {
              tab.addEventListener('click', function () {
                deactvateAllTabs();
                hideTabsContent();
                tab.classList.add('is-active');
                activateTabsContent(tab);
              });
            })
          
            tabs[0].click();
          })();

      }, []);
    
    
    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Liens Utiles<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Liens Utiles</a></li>
                    </ul>
                </nav>
            </div>
        </div>

        <div className="links-container">
            <div className="container-offers" align="center">
                <p className='has-text-primary'>En savoir plus</p>
                <h1 className="title is-1">Liens Utiles</h1>
                <p>Cette page recense les liens utiles vers les sites d’administrations françaises, d’institutions publiques et privées.</p>
                <br/>
                <div id="tabs-with-content">
                <div class="tabs is-toggle is-fullwidth">
                    <ul>
                    <li><a><span class="icon is-small"><i class="vc_tta-icon fas fa-university" aria-hidden="true"></i></span> <span>Administration française</span></a></li>
                    <li><a><span class="icon is-small"><i class="vc_tta-icon fas fa-briefcase" aria-hidden="true"></i></span> <span>Vie des entreprises</span></a></li>
                    <li><a><span class="icon is-small"><i class="vc_tta-icon fas fa-calculator" aria-hidden="true"></i></span> <span>Comptabilité et fiscalités</span></a></li>
                    <li><a><span class="icon is-small"><i class="vc_tta-icon fas fa-gavel" aria-hidden="true"></i></span> <span>Droit et législation</span></a></li>
                    <li><a><span class="icon is-small"><i class="fa-solid fa-users"></i></span> <span>Ressources humaines</span></a></li>
                    </ul>
                </div>
                <div>
                    <section class="tab-content">
                        <ul>
                            <li><a href="https://www.impots.gouv.fr/accueil" target="blank" >Impots.gouv.fr</a></li>
                            <li><a href="https://www.service-public.fr/" target="blank">Service Public :  le site officiel de l’administration française</a></li>
                            <li><a href="https://www.economie.gouv.fr/" target="blank">Ministère de l’économie et des finances</a></li>
                            <li><a href="https://www.entreprises.gouv.fr/fr" target="blank">Direction générale des entreprises</a></li>
                            <li><a href="https://www.insee.fr/fr/information/1972060" target="blank">Liste des Centres de formalités des entreprises (CFE)</a></li>
                            <li><a href="https://www.cget.gouv.fr/" target="blank">Comité général à l’égalité des territoires</a></li>
                            <li><a href="https://www.insee.fr/fr/accueil" target="blank">Institut national de la statistique et des études économiques (INSEE)</a></li>
                        </ul>
                    </section>
                    <section class="tab-content">
                        <ul>
                            <li><a href="https://www.cci.fr/" target="blank" >Portail des Chambres de commerce et d’industrie (CCI)</a></li>
                            <li><a href="https://www.cm-yvelines.fr/" target="blank">Chambre de métiers d'Yvelines</a></li>
                            <li><a href="https://www.artisanat.fr/" target="blank">Portail des Chambres de métiers et de l’artisanat</a></li>
                            <li><a href="https://bpifrance-creation.fr/" target="blank">Agence France Entrepreneur (ex APCE)</a></li>
                            <li><a href="https://www.bpifrance.fr/" target="blank">BPI France</a></li>
                        </ul>
                    </section>
                    <section class="tab-content">
                        <ul>
                            <li><a href="https://www.experts-comptables.fr/" target="blank" >Ordre national des Experts-comptables</a></li>
                            <li><a href="https://www.oec-paris.fr/" target="blank">Ordre des Experts-comptables de Paris</a></li>
                            <li><a href="https://www.cncc.fr/" target="blank">Compagnie des commissaires aux comptes</a></li>
                            <li><a href="https://www.jedeclare.com/prehome" target="blank">JeDeclare.com : Portail pour les télétransmissions fiscales, sociales et comptables</a></li>
                            <li><a href="https://www.urssaf.fr/portail/home.html" target="blank">URSSAF</a></li>
                        </ul>
                    </section>
                    <section class="tab-content">
                        <ul>
                            <li><a href="https://bofip.impots.gouv.fr/" target="blank" >Bulletin officiel des Finances publiques (BOFIP)</a></li>
                            <li><a href="https://www.journal-officiel.gouv.fr/pages/accueil/" target="blank">Journal Officiel</a></li>
                            <li><a href="https://www.legifrance.gouv.fr/" target="blank">Legifrance</a></li>
                            <li><a href="https://www.cnil.fr/" target="blank">Commission Nationale de l’Informatique et des Libertés (CNIL) </a></li>
                            <li><a href="https://www.inpi.fr/" target="blank">Institut National de la Propriété Industrielle</a></li>
                            <li><a href="https://bases-marques.inpi.fr/" target="blank">Base Marque INPI</a></li>
                            <li><a href="https://www.infogreffe.fr/recherche-greffe-tribunal/chercher-greffe-tribunal-de-commerce.html" target="blank">Greffes des Tribunaux de commerce par région</a></li>
                            <li><a href="https://www.huissier-justice.fr/" target="blank">Chambre nationale des huissiers de justice</a></li>
                        </ul>
                    </section>
                    <section class="tab-content lastClass">
                        <ul>
                            <li><a href="https://www.securite-sociale.fr/accueil" target="blank" >Sécurité sociale</a></li>
                            <li><a href="https://www.ameli.fr/" target="blank">L’assurance maladie en ligne (AMELI)</a></li>
                            <li><a href="https://www.secu-independants.fr/" target="blank">Sécurité sociale des indépendants (ex RSI)</a></li>
                            <li><a href="https://www.msa.fr/lfp" target="blank">Mutualité sociale agricole (MSA)</a></li>
                            <li><a href="https://www.urssaf.fr/portail/home.html" target="blank">URSSAF</a></li>
                            <li><a href="https://www.e-ventail.fr/travaux.html" target="blank">Portail dédié aux DADS</a></li>
                            <li><a href="https://www.risquesprofessionnels.ameli.fr/" target="blank">Portail dédié aux risques professionnels</a></li>
                            <li><a href="https://www.pole-emploi.fr/accueil/" target="blank">Pôle Emploi</a></li>
                            <li><a href="https://www.apec.fr/" target="blank">Association pour l’emploi des cadres (APEC)</a></li>
                            <li><a href="https://www.cnav.fr/" target="blank">Caisse nationale d’assurance vieillesse (CNAV)</a></li>
                            <li><a href="https://www.agirc-arrco.fr/" target="blank">Retraite complémentaire des salariés (ARRCO)</a></li>
                            <li><a href="https://www.agirc-arrco.fr/?espace=3" target="blank">Retraite complémentaire des cadres (AGIRC)</a></li>

                        
                        </ul>
                    </section>
                </div>
                </div>
            
            
            </div>
            </div>
            

        <Footer/>
        </>
    );
}

export default Links;