import Footer from "./Footer";
import Header from "./Header";
import img from '../img/Erreur-404.png'
import { useEffect, useState } from "react";

const NotFound = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);


    return(
        <>
            <Header/>
            <div align="center" className="errorPage">
                <img src={img} id="errorImage"/>
                <h1 className="title is-1">Oops ! Cette page n'existe pas</h1>
            </div>
            <Footer/>
        </>
    );
}

export default NotFound;