import 'bulma/css/bulma.min.css';
import '../style/variables.scss';
import '../style/style.scss';

import $ from 'jquery';
import CountUp, { useCountUp } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Header from './Header';
import Loading from './Loader';
import Footer from './Footer';
import com from '../img/iconCom.png';
import feuille from '../img/feuille.png';
import slide1 from '../img/slide1.png';
import slide2 from '../img/slide2.png';
import slide3 from '../img/slide3.jpg';
import imgAPropos from '../img/aPropos.jpg';
import safran from '../img/logoPreview.png';
import bgVideo from '../img/Productive-Morning.mp4';
import scroll from '../img/scroll.gif'
import logoApropos from '../img/logo2.png';

import dossierPaies from '../img/dossierPaies.png';
import consult from '../img/consult.png';
import connect from '../img/connect.png';
import box from '../img/box.png';
import devis from '../img/devis.png';
import doc from '../img/doc.png';
import bandeau from '../img/image.jpg';

import compt from '../img/iconComptable.png';
import social from '../img/iconSocial.png';
import juridique from '../img/iconJuridique.png';
import conseil from '../img/iconConseil.png';

import { useEffect, useState } from "react";


const FirstHome = () => {

     /* ------------------------------------------------------------- GLOBAL JS -------------------------------------------------------------- */

    $(document).ready(function() {
        $("#accueil").addClass("on-page");
     
    });

    useEffect(() => {
    

        setTimeout( () =>{
            $(".anim1").addClass("visible");
        },10);
        setTimeout( () =>{
            $(".anim2").addClass("visible");
        },500);
        setTimeout( () =>{
            $(".anim3").addClass("visible");
        },700);
        setTimeout( () =>{
            $(".anim4").addClass("visible");
        },1500);
        setTimeout( () =>{
            $(".anim5").addClass("visible");
        },1500);
        setTimeout( () =>{
            $(".anim6").addClass("visible");
        },1500);
        setTimeout( () =>{
        $(".textLanding").addClass("start-anim");
        },1500);

    });

    /* ------------------------------------------------------------- REACT JS -------------------------------------------------------------- */
    
    const [isFirst, setFirst] = useState(true);

    return(
        <div className='FirstHome'>
        <Header/>


        <div className='video-container .is-flex'>

        

            <div className='textLanding'>
                    <h1 className='title custom anim anim1 has-text-white'>Le <span className='red'>Cabinet SECLS</span></h1>
                    <h1 className='title custom anim anim2 has-text-white'>vous accueille<img src={feuille}  /> </h1>
                    <div className='bouttonSlider anim anim3' id='bDebut'>
                        <NavLink exact to='/formulaire' className='button is-primary is-medium bStart'><strong>Prendre Rendez-Vous</strong></NavLink>
                    </div>
                    <div className='bouttonSlider anim anim3 hidden' id='bDebut'>
                        <NavLink exact to='/mes-services' className='button is-dark is-medium bStart'><strong>Accéder à mes services</strong></NavLink>
                    </div>
            </div>
            <div>
                 <p className="info1 has-text-white anim anim5"><i class="fa-solid fa-phone"></i> 01.39.58.70.39  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="fa-solid fa-envelope"></i> contact@secls.fr</p>
                 <p className="info2 has-text-white anim anim6"><i class="fa-solid fa-location-dot"></i> Marly-le-Roi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <i class="fa-solid fa-clock"></i> <span className="smallSpan">8h30-12h00 13h30-18h00</span></p>
                 <div className="line"><p>dsdsd</p></div>
                 <div className="infos">
                    <p className="info1 has-text-white anim anim5 hidden"><i class="fa-solid fa-phone"></i> 01.39.58.70.39</p>
                    <p className="info2 has-text-white anim anim6 hidden "><i class="fa-solid fa-location-dot"></i> Marly-le-Roi  </p>
                 </div>
                
            </div>

        </div>

        <div className='aPropos-container' >
            <div className="divImg">
                <img src={logoApropos} id="imgAPropos" className='image'/>
            </div>
            <div className='aPropos-text has-text-justified'>
                <p className='has-text-primary'>Qui sommes-nous ?</p>
                <h1 className='title is-2 move'>Le Cabinet SECLS</h1>
                <p><strong> Le cabinet SECLS est une société d’expertise comptable implantée à Marly-le-Roi (dans le département des Yvelines) depuis 1958.</strong></p>
                <br/>
                <p>

                    Il est dirigé par Luc Lecomte, Expert-comptable et Commissaire aux Comptes, entouré d’une équipe pluridisciplinaire de professionnels qui est à votre écoute pour vous conseiller, construire, développer en matière de gestion comptable, fiscale, financière et sociale.

                    Le cabinet intervient dans tous les secteurs d’activités : artisanat, commerce, industrie, professions libérales, associations.

                    Contactez-nous pour en savoir plus sur notre accompagnement et nos services.

                </p>
                <br/>
                <div align="center">
                    <NavLink exact to='/a-propos' className="button is-primary is-rounded" id='aPropos'><strong>A propos</strong></NavLink>
                </div>
            </div>
        </div>



        <div className="container-missions2">
                <p className='has-text-primary'>Cabinet SECLS</p>
                <h1 className="title is-1">Nos Missions</h1>
                <div className="container-content-missions">
                    <p className='has-text-justified'>L'expert-comptable, dispose de compétences étendues pour vous suivre et vous conseiller durant toute la vie de votre entreprise.
                    
                    Notre cabinet met à votre disposition un savoir-faire reconnu en matière fiscale, sociale, juridique ou encore de conseils.<br /><br />
                    En relation constante avec les organes décisionnels de votre entreprise, il vous assure une prestation fiable et rapide ainsi qu'un conseil permanent dans les domaines cruciaux de la vie de votre entreprise. Nous nous impliquons directement dans votre stratégie de réussite et nous nous engageons à vos côtés afin de contribuer à faire de votre structure une entreprise dynamique et adaptée aux besoins de votre clientèle.
                    </p>

                    <div className="columns missions">
                        <div className="column bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={compt} alt="" className='iconMissions'/>
                                <h1 className="title">Missions Comptables</h1>
                                </div>

                                <h1 className="title is-5"><br /> Participation à l'établissement des comptes</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Mise en place du Plan comptable adapté à l'entreprise</span>  <br />
                                    • <span className="petit has-text-left">Surveillance, tenue de comptabilités</span>  <br />
                                    • <span className="petit has-text-justified">Contrôle des services comptables </span>  <br />
                                    • <span className="petit has-text-justified">Mise en place et suivi de la comptabilité analytique d'exploitation</span>  <br />
                                    • <span className="petit has-text-justified">Assistance comptable de toute nature</span>  <br />
                                    • <span className="petit has-text-justified">Établissement des situations périodiques, des bilans et comptes de résultat</span>  <br />
                                    • <span className="petit has-text-justified">Délivrance du visa aux adhérents des centres de gestion agréés</span>  <br />
                                    • <span className="petit has-text-justified">Établissement de situations prévisionnelles</span>  <br />
                                    • <span className="petit has-text-justified">Établissement des comptes de groupes, consolidation des bilans et des comptes</span>  <br />
                                    • <span className="petit has-text-justified">Établissement du tableau de financement</span>  <br />
                                </div>
                                <h1 className="title is-5"><br />Révision & Certification</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Révision contractuelle des comptes</span>  <br />
                                    • <span className="petit has-text-left">Révision des procédures de contrôle interne </span>  <br />
                                    • <span className="petit has-text-justified">Révision légale des comptes (commissariat aux comptes) </span>  <br />
                                    • <span className="petit has-text-left">Révision avant prise de participation ;</span>  <br />
                                    • <span className="petit has-text-left">Commissariat aux apports </span>  <br />
                                </div>
                            </div>
                            
                        </div>
                        <div className="column icon2 bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={juridique} alt="" className='iconMissions2'/>
                                <h1 className="title">Missions Juridiques & Fiscales</h1>
                                </div>

                                <h1 className="title is-5"><br /> Droit des sociétés</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Choix de la forme sociale </span>  <br />
                                    • <span className="petit has-text-left">Réalisation de toutes opérations (constitution, augmentation de capital, cession de parts, fusion, transformation, scission, dissolution, liquidation) </span>  <br />
                                    • <span className="petit has-text-justified">Secrétariat de sociétés : comptes-rendus de conseils d'administration et d'assemblées générales et formalités afférentes</span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Droit de l'entreprise individuelle</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Droit patrimonial </span>  <br />
                                    • <span className="petit has-text-left">Prévoyance sociale du chef d'entreprise </span>  <br />
                                    • <span className="petit has-text-justified">Transmission</span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Droit fiscal</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Études et consultations sur les problèmes d'ordre fiscal </span>  <br />
                                    • <span className="petit has-text-left">Participation à l'établissement des déclarations fiscales : impôts directs, TVA et taxes assimilées, fiscalité immobilière, droits d'enregistrement et droits de mutation, douanes, autres impôts et taxes</span>  <br />
                                    • <span className="petit has-text-justified">Assistance du contribuable à l'occasion de vérifications</span>  <br />
                                </div>
                            </div>
                            
                        </div>

                    </div>

                    <div className="columns missions">
                        <div className="column bgcolumn">
                            <div>
                            <div align="center">
                                    <img src={conseil} alt="" className='iconMissions2'/>
                                    <h1 className="title">Missions de Conseil</h1>
                                </div>

                                <h1 className="title is-5"><br /> Conseil en gestion générale</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Gestion prévisionnelle</span>  <br />
                                    • <span className="petit has-text-left">Analyse de coûts, de marges, de rentabilité</span>  <br />
                                    • <span className="petit has-text-justified">Projets d'investissement : coût et rentabilité </span>  <br />
                                    • <span className="petit has-text-justified">Tableaux de bord, contrôle budgétaire</span>  <br />
                                    • <span className="petit has-text-justified">Étude d'optimisation du profil </span>  <br />
                                    • <span className="petit has-text-justified">Ratios de gestion</span>  <br />
                                </div>
                                <h1 className="title is-5"><br /> Relations avec les organismes bancaires et financiers </h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Constitution de dossiers dans le cadre de créations et d'implantations d'entreprise</span>  <br />
                                    • <span className="petit has-text-left">Analyse de structure financière</span>  <br />
                                    • <span className="petit has-text-justified">Établissement de plans de trésorerie </span>  <br />
                                    • <span className="petit has-text-justified">Construction de plans de financement à moyen et long terme</span>  <br />
                                    • <span className="petit has-text-justified">Choix des modes de financement et établissement de dossiers de crédit </span>  <br />
                                    • <span className="petit has-text-justified">Tableau d'emplois et de ressources (historique et prévisionnel)</span>  <br />
                                </div>

                            </div>
                            
                        </div>
                        <div className="column icon2 bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={social} alt="" className='iconMissions'/>
                                <h1 className="title">Missions Sociales</h1>
                                </div>
                               
                                <h1 className="title is-5"><br />Droit du travail</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Application de la législation et de la réglementation du travail </span>  <br />
                                    • <span className="petit has-text-left">Consultations sur les problèmes spécifiques au droit du travail </span>  <br />
                                    • <span className="petit has-text-justified">Étude et rédaction de contrats de travail  </span>  <br />
                                    • <span className="petit has-text-justified">Problèmes de licenciement </span>  <br />
                                    • <span className="petit has-text-justified">Problèmes relatifs à la représentation dans l'entreprise, délégués du personnel, comité d'entreprise, droit syndical </span>  <br />
                                    • <span className="petit has-text-justified">Étude et application des textes relatifs à la participation des travailleurs aux fruits de l'expansion</span>  <br />
                                    • <span className="petit has-text-justified">Système d'intéressement (mise en œuvre, contrôle) </span>  <br />
                                    • <span className="petit has-text-justified">Formation professionnelle continue</span>  <br />
                                </div>
                                <h1 className="title is-5"><br />Prévoyance sociale</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Consultation sur les problèmes spécifiques à la Sécurité sociale et à la prévoyance sociale (notamment les régimes de retraite)</span>  <br />
                                    • <span className="petit has-text-left">Établissement des bulletins de paie et de la comptabilité des salaires </span>  <br />
                                    • <span className="petit has-text-justified">Établissement des déclarations de cotisations sociales </span>  <br />
                                    • <span className="petit has-text-justified">Assistance à l'occasion de réclamation et de vérification des administrations sociales</span>  <br />
                                </div>

                            </div>
                            
                        </div>

                        <div className="column icon2 bgcolumn">
                            <div>
                                <div className="" align="center">
                                <img src={com} alt="" className='iconMissionsCom'/>
                                <h1 className="title">Commissariat aux Comptes</h1>
                                </div>

                                <h1 className="title is-5"><br /> Audit Légal</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Certification des comptes annuels </span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Certification des comptes annuels</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Vérifications spécifiques </span>  <br />
                                    • <span className="petit has-text-left">Rapport de Gestion </span>  <br />
                                </div>

                                <h1 className="title is-5"><br /> Documents adressés aux actionnaires</h1>
                                <div align="left">
                                    • <span className="petit has-text-left">Conventions réglementées</span>  <br />
                                    • <span className="petit has-text-left">Interventions connexes</span>  <br />
                                    • <span className="petit has-text-left">Augmentation de capital</span>  <br />
                                    • <span className="petit has-text-left">Acomptes sur dividendes</span>  <br />
                                    • <span className="petit has-text-left">Révélations de faits délictueux</span>  <br />
                                    • <span className="petit has-text-left">Alerte</span>  <br />
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
              
        </div>



        <ul className='chiffres-container'>
            <div className='centeredNumbers' >
            <li>
                <div className='has-text-centered'>
                    <i class="fa-solid fa-receipt has-text-primary fa-4x"></i>
                    <h1 className='title is-2 has-text-white' id="number">

                                    <CountUp start={isFirst ? 0 : null} end={9000} prefix="+" duration="3">

                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setFirst(false)
                                                }
                                            }} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                            
                    </h1>
                    <small><strong className='has-text-white'>Bulletins de paie <br/>produits par an</strong></small>
                </div>
            </li>

            <li>
                <div className='has-text-centered'>
                    <i class="fa-solid fa-file-invoice has-text-primary fa-4x"></i>
                    <h1 className='title is-2 has-text-white' id="number">
                                <CountUp start={isFirst ? 0 : null} end={350} prefix="+" duration="3">

                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setFirst(false)
                                                }
                                            }} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                </CountUp>
                    </h1>
                    <small><strong className='has-text-white'>Dossiers comptables <br/>traités par an</strong></small>
                </div>
            </li>

            <li>
                <div className='has-text-centered'>
                    <i class="fa-solid fa-users has-text-primary fa-4x"></i>
                    <h1 className='title is-2 has-text-white' id="number">
                                    <CountUp start={isFirst ? 0 : null} end={1400} prefix="+" duration="3">

                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setFirst(false)
                                                }
                                            }} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>

                    </h1>
                    <small><strong className='has-text-white'>Clients accompagnés<br/>depuis 1958</strong></small>
                </div>
            </li>

            <li>
                <div className='has-text-centered specialChiffre'>
                    <i class="fa-solid fa-mug-saucer has-text-primary fa-4x bb"></i>
                    <h1 className='title is-2 has-text-white' id="numberLow">
                                <CountUp start={isFirst ? 0 : null} end={10} duration="3">

                                        {({ countUpRef }) => (
                                            <VisibilitySensor onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setFirst(false)
                                                }
                                            }} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                </CountUp>
                    </h1>
                    <small><strong className='has-text-white' id="servicesPoint">Collaborateurs</strong></small>
                </div>
            </li>
            <li></li>
            </div>

        </ul>

        <div align="center">
            <p className='has-text-primary' >Nos solutions</p>
            <h1 className='title is-2'>Services</h1>
        </div>

        <div className='containerServices' align="center">
            <div class = "columns is-variable is-4" id="services">

                <div class = "column">
                    <NavLink exact to='/services/consultation'>
                        <div class = "serviceColumn">
                            <img src={consult} />
                            <h1 className='title is-4 has-text-primary'><br/> QuadraWeb</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Comptabilité générale, États comptables et financiers, Tableaux de bord et Clôture annuelle/périodique.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/dossier-paies'>
                        <div class = "serviceColumn">
                            <img src={dossierPaies} />
                            <h1 className='title is-4 has-text-primary'><br/> Paie</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Simplissité, souplesse et sécurité. Le Cabinet SECLS prends en charge la personnalisation de vos dossiers de paie.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/quadra-connect'>
                        <div class = "serviceColumn">
                            <img src={connect} />
                            <h1 className='title is-4 has-text-primary'><br/> Connect</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Pilotez plus facilement votre activité en ayant un accès complet à l'outil de production comptable.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>
                
                </div>
                
        </div>

        <div className='containerServices' align="center" id='column2'>
            <div class = "columns is-variable is-4" id="services">

                <div class = "column">
                    <NavLink exact to='/services/box'>
                        <div class = "serviceColumn">
                            <img src={box} />
                            <h1 className='title is-4 has-text-primary'><br/> Box</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Le service de dépôt/consultation de documents comptables.
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/devis-fact'>
                        <div class = "serviceColumn">
                            <img src={devis} />
                            <h1 className='title is-4 has-text-primary'><br/>DevisFact</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                L'outil de facturation qui vous simplifie la vie. Il permet d'établir des devis, factures d'accomptes, factures classiques...
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>

                <div class = "column">
                    <NavLink exact to='/services/people-doc'>
                        <div class = "serviceColumn">
                            <img src={doc} />
                            <h1 className='title is-4 has-text-primary'><br/> PeopleDoc</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Vous cherchez une solution moderne et simple pour distribuer vos bulletins de payes ?
                            </p>
                            
                            <p className='has-text-primary'><br/>En savoir plus &nbsp;<span><i class="fa-solid fa-right-long"></i></span></p>
                        </div>
                    </NavLink>
                </div>
                                           
                </div>
                
        </div>
        {/*
        <div className='clients'>
            <div align='center'>
                <p className='has-text-primary'>Nos Partenaires</p>
                <h1 className='title'>Ils nous ont fait confiance</h1>

                <div id='clientDiv'>
                    <div class = "columns is-variable is-4" id="services">
                            <div class = "column">
                                <img src={safran} />
                            </div>
                            <div class = "column">
                                <img src={safran} />
                            </div>
                            <div class = "column">
                                <img src={safran} />
                            </div>
                            <div class = "column">
                                <img src={safran} />
                            </div>
                            <div class = "column">
                                <img src={safran} />
                            </div>
                            <div class = "column">
                                <img src={safran} />
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        */}
        <div id="plan">
            
        </div>
        <div className='contact' align="center" >
            <div align='center'>
                <p className='has-text-primary'><br/>Comment nous joindre ?</p>
                <h1 className='title'>Nous Contacter</h1>
            </div>

            <div className='contactStructure'>
                <iframe id="google"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.2031618401375!2d2.091939216050231!3d48.87340347928888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6881c865c80a7%3A0xe4214d473cafb3df!2sS.E.C.L.S%20Soci%C3%A9t%C3%A9%20d&#39;Expertise%20Comptable%20Lecomte%20Sauviat!5e0!3m2!1sfr!2sfr!4v1655151541431!5m2!1sfr!2sfr" 
                    width="800" 
                    height="600" 
                    style={{border : 0}}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>

                <div className='textContact' >
                    <div className='columns is-flex-direction-column'>
                        <div className='column'>
                            <h1 className='title is-3'><i class="fa-solid fa-location-dot has-text-primary"></i> Adresse</h1>
                            <p class="has-text-centered">12 Av. de Saint-Germain </p>
                            <p class="has-text-centered">78160 Marly-le-Roi</p>
                        </div>
                        <div className='column'>
                            <h1 className='title is-3'><i class="fa-solid fa-envelope has-text-black has-text-primary"></i> Contact</h1>
                            <p class="has-text-centered">01.39.58.70.39</p>
                            <p class="has-text-centered"><a href="mailto:contact@secls.fr">contact@secls.fr</a></p>
                        </div>
                        <div className='column'>
                            <h1 className='title is-3'><i class="fa-solid fa-clock has-text-primary"></i> Horaires</h1>
                            <p>Du lundi au vendredi de 8h30 <br /> à 12h00 et de 13h30 à 18h00</p>
                           
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        <Footer/>
        </div>
    );
}

export default FirstHome;

