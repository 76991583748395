import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg';
import link from '../img/links.jpg';

import dossierPaies from '../img/dossierPaies.png';
import consult from '../img/consult.png';
import connect from '../img/connect.png';
import box from '../img/box.png';
import devis from '../img/devis.png';
import doc from '../img/doc.png';
import { useEffect } from "react";

function ServicesLinks() {

    useEffect( () => {
        window.scrollTo(0, 0);
    });

    return ( 
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertop'>
                <h1 className='title has-text-white is-1' id="big">Mes Services<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Mes Services</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="container-services-links is-flex">
            <p className='has-text-primary'>Cabinet SECLS</p>
            <h1 className="title is-1">Mes Services</h1>
            <div className='containerServices' align="center">
            <div class = "columns is-variable is-4" id="services">

                <div class = "column">
                    <a href="http://qweb.secls.fr/">
                        <div class = "serviceColumn">
                            <img src={consult} />
                            <h1 className='title is-4 has-text-primary'><br/> QuadraWeb</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Accéder à ce service <img src={link} alt="" id="linkSize" />
                            </p>
                        </div>
                    </a>
                </div>

                <div class = "column">
                    <a href="https://leportail.cegid.com" target='blank'>
                        <div class = "serviceColumn">
                            <img src={connect} />
                            <h1 className='title is-4 has-text-primary'><br/> Connect</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Accéder à ce service <img src={link} alt="" id="linkSize" />
                            </p>
                        </div>
                    </a>
                </div>
                
                </div>
                
        </div>

        <div className='containerServices' align="center" id='column2'>
            <div class = "columns is-variable is-4" id="services">

                <div class = "column">
                    <a href="https://leportail.cegid.com" target='blank'>
                        <div class = "serviceColumn">
                            <img src={box} />
                            <h1 className='title is-4 has-text-primary'><br/> Box</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Accéder à ce service <img src={link} alt="" id="linkSize" />
                            </p>
                        </div>
                    </a>
                </div>

                <div class = "column">
                    <a href='https://leportail.cegid.com' target='blank'>
                        <div class = "serviceColumn">
                            <img src={devis} />
                            <h1 className='title is-4 has-text-primary'><br/>DevisFact</h1>
                            <p className='textService is-size-6 has-text-grey'>
                                Accéder à ce service <img src={link} alt="" id="linkSize" />
                            </p>
                            
                        </div>
                    </a>
                </div>              
                </div>
                
                </div>
        </div>
        <Footer/>
        </>
     );
}

export default ServicesLinks;