import Footer from "./Footer";
import Header from "./Header";
import bg from '../img/aProposBg.jpg'
import logo from '../img/logo2.png'
import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const Form = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return(
        <>
        <Header/>
        <div class="container-bg">
            <img src={bg} id="fullBg" />
            <div className='layertopForm'>
                <h1 className='title has-text-white is-1' id="bigTest">Mon Rendez-Vous<br/><br/></h1>
                <nav class="breadcrumb has-arrow-separator specialBread" aria-label="breadcrumbs">
                    <ul>
                        <li ><a href="/accueil" class="has-text-grey">&ensp;&ensp;Accueil&nbsp;</a></li>
                        <li><a href="#" class="has-text-grey">&nbsp;Navigation&nbsp;</a></li>
                        <li class="is-active" ><a href="#" aria-current="page" class="has-text-grey">&nbsp;Prendre RDV</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className="ZoneForm">
        <div className="containerForm">
                <div className="titleeeee" >
                    <p className='has-text-primary has-text-centered'>Prendre RDV</p>
                    <h1 className="title has-text-centered is-1" id="contactligne">Contact</h1>
                    </div>

                <div className="container-all-form">

                    <div className="divLeft">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.2029767677554!2d2.0919392157361205!3d48.87340700760323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6881c865c80a7%3A0xe4214d473cafb3df!2sS.E.C.L.S%20Soci%C3%A9t%C3%A9%20d&#39;Expertise%20Comptable%20Lecomte%20Sauviat!5e0!3m2!1sfr!2sfr!4v1655727432346!5m2!1sfr!2sfr" width="700" height="1000" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className="divRight">
                    <form action="https://formspree.io/f/mzbowogq" method="POST" id="formContact">
                
                <div class="field">
                <label class="label">Civilité <span className="has-text-primary">*</span></label>
                <div class="control">
                    <div class="select">
                    <select name="Civilité" required>
                        <option>Veuillez sélectionner</option>
                        <option>Mme.</option>
                        <option>M.</option>
                    </select>
                    </div>
                </div>
                </div>


                    <div className="testtt">
                    <div class="field">
                        <label class="label">Prénom <span className="has-text-primary">*</span></label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" placeholder="Prénom" name="Prénom" required/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                    </div>
                    </div>

                    <div class="field">
                    <label class="label">Nom <span className="has-text-primary">*</span></label>
                    <div class="control has-icons-left">
                        <input class="input" type="text" placeholder="Nom" name="Nom" required/>
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                </div>
                </div>
                    </div>


                <div class="field">
                <label class="label">Email <span className="has-text-primary">*</span></label>
                <div class="control has-icons-left">
                    <input class="input" type="email" placeholder="Email" name="Email" required/>
                    <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                    </span>
                </div>
                </div>

                <div class="field">
                <label class="label">Téléphone</label>
                <div class="control has-icons-left">
                    <input class="input" type="text" placeholder="Téléphone" name="Téléphone"/>
                    <span class="icon is-small is-left">
                    <i class="fas fa-phone"></i>
                    </span>
                </div>
                </div>

                <div className="testtt">

                <div class="field">
                <label class="label">Nom de l'entreprise <span className="has-text-primary">*</span></label>
                <div class="control has-icons-left">
                    <input class="input" type="text" placeholder="Entreprise" name="Nom de l'Entreprise" required />
                    <span class="icon is-small is-left">
                    <i class="fa-solid fa-briefcase"></i>
                    </span>
                </div>
                </div>

                <div class="field spacee">
                <label class="label">Nombre de collaborateurs <span className="has-text-primary">*</span></label>
                <div class="control has-icons-left">
                    <input class="input" type="text" placeholder="Entreprise" name="Nombre de collaborateurs" required/>
                    <span class="icon is-small is-left">
                    <i class="fa-solid fa-briefcase"></i>
                    </span>
                </div>
                </div>

                </div>

                <div class="field">
                <label class="label">Votre fonction professionnelle</label>
                <div class="control has-icons-left">
                    <input class="input" type="text" placeholder="Entreprise" name="Fonction professionnelle" />
                    <span class="icon is-small is-left">
                    <i class="fa-solid fa-briefcase"></i>
                    </span>
                </div>
                </div>

                <div class="field">
                <label class="label">Objet  <span className="has-text-primary">*</span></label>
                <div class="control">
                    <div class="select">
                    <select name="Objet" required>
                        <option>De quoi traite votre message ?</option>
                        <option>Service - Consultation</option>
                        <option>Service - Dossier Paies</option>
                        <option>Service - Quadra Connect</option>
                        <option>Service - Box</option>
                        <option>Service - DevisFact</option>
                        <option>Service - PeopleDoc</option>
                        <option>Plusieurs services</option>
                        <option>Autres</option>
                    </select>
                    </div>
                </div>
                </div>

                <div class="field">
                <label class="label">Message <span className="has-text-primary">*</span></label>
                <div class="control">
                    <textarea class="textarea" placeholder="Mon message" name="Message" required></textarea>
                </div>
            </div>

            <label class="checkbox">
                <input type="checkbox" required/> J'ai pris connaissance et j'accepte la <NavLink exact to='/mentions-legales'>Politique de Confidentialité</NavLink>
                </label>

            <small>
            <br/><br/>En validant ce formulaire, vous consentez à ce que vos données personnelles soient traitées par Cabinet SECLS responsable du traitement, pour la gestion et le suivi de votre demande de contact. À ces fins les champs visés par un astérisque sont obligatoires. Pour en savoir plus sur vos droits ainsi que nos traitements et pratiques en matière de données personnelles, cliquez sur notre <NavLink exact to='/mentions-legales'>Politique de Confidentialité</NavLink>.
            </small>
            <div className="captcha" align="center">
                <ReCAPTCHA
                    //sitekey="6LfOB3QgAAAAALZyIz5Vk2IVmay2ZSDT9sNMqqgY"
                    //sitekey="6LfNTpcgAAAAACDK7j2XgWB8fdImSBWL45oWkOAD"
                    sitekey="6LemWyQhAAAAACvejLQ-txeBT1atbI8IDENvjubJ"
                />
            </div>
            
            <div class="field is-grouped" id="buttonForm">
                <div class="control">
                    <button class="button is-primary">Envoyer</button>
                </div>
                <div class="control">
                    <a class="button is-primary is-light" href="/">Retour</a>
                </div>
                </div>
                </form>
                
                    </div>
                </div>

              
        </div>
        </div>

        <div className="lastDivForm">
            <div className="columns is-full">
                <div className="column">
                        <h1 className="title is-5"><br /> CABINET SECLS</h1>
                    <p>12 Av. de Saint-Germain
                        <br /> 78160 Marly-le-Roi
                    </p>
                    <p className="has-text-medium"><i class="fa-solid fa-phone"></i> 01.39.58.70.39</p>
                    <h1 className="title is-5"><br/>HORAIRES D'OUVERTURE</h1>
                    <p> Du lundi au vendredi de 8h30 à 12h30 et de 14h00 à 18h00</p>
                </div>
                <div className="column is-vcentered">
                        <img src={logo} alt="" id="formlogo"/>
                </div>
            </div>
           
        </div>
        
        <div className="esp"></div>
            
        <Footer/>
        </>
    );
}   

export default Form;