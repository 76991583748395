import Home from "./components/FirstHome";
import NotFound from "./components/NotFound";
import FirstHome from "./components/FirstHome";
import Loading from "./components/Loader";
import { useEffect, useState } from "react";
import {BrowserRouter,Routes ,Route} from "react-router-dom";
import Form from "./components/Form";
import Apropos from "./components/Apropos";
import Jobs from "./components/Jobs";
import Faq from "./components/Faq";
import Links from "./components/Links";
import Admin from "./components/Admin";
import $ from 'jquery';
import Legal from "./components/Legal";
import Consultation from "./components/services/Consultation";
import Paies from "./components/services/Paies";
import Quadra from "./components/services/Quadra";
import Box from "./components/services/Box";
import Devis from "./components/services/Devis";
import People from "./components/services/People";
import ServiceAll from "./components/services/ServiceAll";
import Plan from "./components/Plan";
import ServicesLinks from "./components/ServicesLinks";
import Missions from "./components/Missions";
import Panel from "./components/Panel";
import Equipe from "./components/Equipe";

function App() {

  const [isLoading, setLoading] = useState(true);

  useEffect(function(){
    $(document).ready( () => {
        setTimeout(() => {
            var loader = $(".Loader");
            var logo = $('#t');
            loader.addClass('fadeLoader');
            logo.addClass('logoDiv');
          }, 2000);
        
          setTimeout(() => {
                setLoading(false);
          }, 3000);
        },[]);
      
    })


if(isLoading){
    return <Loading/>
}
  return (
    <>
      <BrowserRouter>
          <Routes>
                <Route exact path="/accueil" element={<FirstHome/>}/>
                <Route exact path="/" element={<FirstHome/>}/>
                <Route exact path="/formulaire" element={<Form/>}/>
                <Route exact path="/a-propos" element={<Apropos/>}/>
                <Route exact path="/plan" element={<Plan/>}/>
                <Route exact path="/carriere" element={<Jobs/>}/>
                <Route exact path="/mes-services" element={<ServicesLinks/>}/>
                <Route exact path="/missions" element={<Missions/>}/>
                <Route exact path="/faqs" element={<Faq/>}/>
                <Route exact path="/liens-utiles" element={<Links/>}/>
                <Route exact path="/administration" element={<Admin/>}/>
                <Route exact path="/administration/panel" element={<Panel/>}/>
                <Route exact path="/services/services" element={<ServiceAll/>}/>
                <Route exact path="/services/consultation" element={<Consultation/>}/>
                <Route exact path="/services/dossier-paies" element={<Paies/>}/>
                <Route exact path="/services/quadra-connect" element={<Quadra/>}/>
                <Route exact path="/a-propos/equipe" element={<Equipe/>}/>
                <Route exact path="/services/box" element={<Box/>}/>
                <Route exact path="/services/devis-fact" element={<Devis/>}/>
                <Route exact path="/services/people-doc" element={<People/>}/>
                <Route exact path="/mentions-legales" element={<Legal/>}/>
                <Route exact path="*" element={<NotFound/>}/>
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
